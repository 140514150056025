<!-- eslint-disable -->
<template>
  <div class="d-flex align-items-center justify-content-center" v-if="show">
    <div class="row w-100 mx-0 auth-page">
      <div class="col-md-12 col-xl-12 mx-auto">
        <div class="card">
          <div class="row">
            <div class="col-md-12 stretch-card">
              <div class="card">
                <div class="card-body">
                  <div class="noble-ui-logo d-block mb-2">Renouveler<span>&nbsp; Abonnement</span></div>
                  <form @submit.prevent="SubmitData">
                    <div class="row mt-5 mb-5">
                      <div class="col-md-6" v-if="restaurant.serieNumber">
                        <div class="">
                          <input type="text" readonly class="form-control"
                            :value="`Numéro de série : ${restaurant.serieNumber}`" />
                        </div>
                      </div>
                      <div class="col-md-6" v-if="restaurant.LibSite">
                        <div class="">
                          <input type="text" readonly class="form-control"
                            :value="`Nom du restaurant : ${restaurant.LibSite}`" />
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-12">
                        <div class="mb-3">
                          <label class="form-label">Motif</label>
                          <textarea class="form-control" rows="5" v-model="motif"></textarea>
                        </div>
                      </div>
                      <div class="col-md-12 mt-5">
                        <div class="mb-3">
                          <button type="submit" class="btn btn-primary w-100 submit">
                            Résilier
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<!-- eslint-disable -->
<script>
import { mapActions, mapGetters } from "vuex";

import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
export default {
  name: "AddrestaurantView",
  components: {
    VueDatePicker
  },
  computed: {
    ...mapGetters({ isRole: "isRole", isAuthenticated: "isAuthenticated", BaseUrl: "StateBase" }),

  },
  mounted() {
    if (this.$route.params.id) {
      const get = async () => {
        try {
          this.restaurant = await this.getRestaurantById(this.$route.params.id);
          this.id = this.restaurant._id;
          this.name = this.restaurant?.name;
          this.LibSite = this.restaurant?.LibSite;
          this.show = true;
        } catch (error) {
          if (error) {
            this.show = false;
            console.log(error);
            //this.showAlert("Forbidden resource");
          }
        }
      };
      if (this.isAuthenticated && this.$store.state.roles.includes(this.isRole))
        get();
    }
  },
  data() {
    return {
      restaurant: null,
      users: [],
      id: "",
      name: "",
      LibSite: "",
      motif: '',
      latestAbonnement: {},
      abonnements: [],
      show: false,
    };
  },
  methods: {
    showSuccess() {
      this.$swal({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        icon: "success",
        title: "edit is successfully done",
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
    },
    showAlert(error) {
      this.$swal({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        icon: "error",
        title: error,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
    },
    ...mapActions(["resilierAbonnement", "getRestaurantById"]),
    async SubmitData() {
      const restaurant = {
        id: this.id,
        name: this.name,
        abonnements: this.motif
      };
      try {
        if (
          this.id === ""
        ) {
          this.showAlert("Veuillez remplir tous les champs");
        } else {
          await this.resilierAbonnement(restaurant);
          this.$router.push({
            name: "details-restaurant",
            params: { id: this.id },
          });
        }
      } catch (error) {
        if (error.response.status === 403) {
          this.showAlert("Forbidden");
          return;
        }
        this.showAlert("Il y a un problème, veuillez réessayer s'il vous plaît");
      }
    },
  },
};
</script>
