<!-- eslint-disable -->
<script setup>
import weatherComponent from "@/components/weather/index.vue";
</script>
<!-- eslint-disable -->
<template>
  <div class="row mb-3">
    <div class="col-xl-6 col-lg-6 mb-3 d-flex align-items-stretch card-100">
      <div class="card card-stats mb-4 mb-xl-0 position-relative min-h-100 ">
        <SectionLoaderView v-if="isRequestStatPrincipal" />
        <div class="card-body" v-if="dashboard?.stat_principal?.data">
          <div
            class="statistique statistique-font-size font-weight-bold mb-0 d-flex height-100 sjustify-content-center align-items-center">
            <div class="w-100">
              <div class="text-centers text-uppercase font-size-20">
                <div>
                  <span class="percentage">
                    <i v-if="dashboard?.stat_principal?.percentage?.percentageCouvert?.arrow == 'down'"
                      class="fa fa-arrow-down red" aria-hidden="true"></i>
                    <i v-if="dashboard?.stat_principal?.percentage?.percentageCouvert?.arrow == 'up'"
                      class="fa fa-arrow-up green" aria-hidden="true"></i>
                    <span :class="{ 'red': dashboard?.stat_principal?.percentage?.percentageCouvert?.arrow == 'down' }">
                      {{
                        dashboard?.stat_principal?.percentage?.percentageCouvert?.value }} % </span>
                  </span>
                  {{ $t("content.pages.dashboard.cards.couvert_n.couvert") }} :
                  <span class="float-end">
                    {{
                      dashboard?.stat_principal?.data[0]?.entete
                        ? dashboard?.stat_principal?.data[0]?.entete?.totalCouvertTable
                        : "0"
                    }}
                  </span>
                </div>
              </div>
              <div class="text-centers text-uppercase font-size-20 mt-2">
                <div>
                  <span class="percentage">
                    <i v-if="dashboard?.stat_principal?.percentage?.percentageCouvertMoyenneTable?.arrow == 'down'"
                      class="fa fa-arrow-down red" aria-hidden="true"></i>
                    <i v-if="dashboard?.stat_principal?.percentage?.percentageCouvertMoyenneTable?.arrow == 'up'"
                      class="fa fa-arrow-up green" aria-hidden="true"></i>
                    <span
                      :class="{ 'red': dashboard?.stat_principal?.percentage?.percentageCouvertMoyenneTable?.arrow == 'down' }">
                      {{
                        dashboard?.stat_principal?.percentage?.percentageCouvertMoyenneTable?.value }} % </span>
                  </span>
                  {{ $t("content.pages.dashboard.cards.couvert_n.couvert_moyen") }}:
                  <span class="float-end">
                    {{
                      dashboard?.stat_principal?.data[0]?.entete
                        ? dashboard?.stat_principal?.data[0]?.entete?.totalCouvertMoyenneTable?.toFixed(2)
                        : "0.00"
                    }}
                    €
                  </span>
                </div>
              </div>
              <div class="text-centers text-uppercase font-size-20 mt-2">
                <div>
                  <span class="percentage">
                    <i v-if="dashboard?.stat_principal?.percentage?.percentageChiffreCATable?.arrow == 'down'"
                      class="fa fa-arrow-down red" aria-hidden="true"></i>
                    <i v-if="dashboard?.stat_principal?.percentage?.percentageChiffreCATable?.arrow == 'up'"
                      class="fa fa-arrow-up green" aria-hidden="true"></i>
                    <span
                      :class="{ 'red': dashboard?.stat_principal?.percentage?.percentageChiffreCATable?.arrow == 'down' }">
                      {{
                        dashboard?.stat_principal?.percentage?.percentageChiffreCATable?.value }} % </span>
                  </span>
                  {{ $t("content.pages.dashboard.cards.couvert_n.ca_table") }} :
                  <span class="float-end">
                    {{
                      dashboard?.stat_principal?.data[0]?.entete
                        ? dashboard?.stat_principal?.data[0]?.entete?.totalChiffreCATable.toFixed(
                          2
                        )
                        : "0.00"
                    }}
                    €
                  </span>
                </div>
              </div>
              <div class="text-centers text-uppercase font-size-20 mt-2">
                <div>
                  <span class="percentage">
                    <i v-if="dashboard?.stat_principal?.percentage?.percentageChiffreCA?.arrow == 'down'"
                      class="fa fa-arrow-down red" aria-hidden="true"></i>
                    <i v-if="dashboard?.stat_principal?.percentage?.percentageChiffreCA?.arrow == 'up'"
                      class="fa fa-arrow-up green" aria-hidden="true"></i>
                    <span
                      :class="{ 'red': dashboard?.stat_principal?.percentage?.percentageChiffreCA?.arrow == 'down' }">
                      {{
                        dashboard?.stat_principal?.percentage?.percentageChiffreCA?.value }} % </span>
                  </span>
                  {{ $t("content.pages.dashboard.cards.couvert_n.ca") }} :
                  <span class="float-end">
                    {{
                      dashboard?.stat_principal?.data[0]?.entete
                        ? dashboard?.stat_principal?.data[0]?.entete?.totalChiffreCA.toFixed(
                          2
                        )
                        : "0.00"
                    }}
                    €
                  </span>
                </div>
              </div>
              <div class="text-centers text-uppercase font-size-20 mt-2">
                <div>
                  <span class="percentage">
                    <i v-if="dashboard?.stat_principal?.percentage?.percentageValeur?.arrow == 'down'"
                      class="fa fa-arrow-down red" aria-hidden="true"></i>
                    <i v-if="dashboard?.stat_principal?.percentage?.percentageValeur?.arrow == 'up'"
                      class="fa fa-arrow-up green" aria-hidden="true"></i>
                    <span :class="{ 'red': dashboard?.stat_principal?.percentage?.percentageValeur?.arrow == 'down' }">
                      {{
                        dashboard?.stat_principal?.percentage?.percentageValeur?.value }} % </span>
                  </span>
                  {{ $t("content.pages.dashboard.cards.couvert_n.reglements") }} :
                  <span class="float-end">
                    {{
                      dashboard?.stat_principal?.data[0]?.reglements
                        ? dashboard?.stat_principal?.data[0]?.reglements?.TotalValeur.toFixed(2)
                        : "0.00"
                    }}
                    €
                  </span>
                </div>
              </div>
              <div class="text-center text-uppercase hr-border">
                <h3 v-if="datePicker?.defaultDates?.type != null">
                  {{ $t(`content.button.${datePicker?.defaultDates?.type}`) }}
                </h3>
                <h3 v-else>
                  {{ 'Personnalisé' }}
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-6 col-lg-6 mb-3 d-flex align-items-stretch card-100">
      <div class="card card-stats mb-4 mb-xl-0 position-relative">
        <SectionLoaderView v-if="isRequestStatPrincipal" />
        <div class="card-body" v-if="dashboard?.stat_principal?.data">
          <div
            class="statistique statistique-font-size font-weight-bold mb-0 d-flex height-100 sjustify-content-center align-items-center">
            <div class="w-100">
              <div class="text-centers text-uppercase font-size-20">
                <div>
                  {{ $t("content.pages.dashboard.cards.couvert_n_1.couvert") }} :<span class="float-end">
                    {{
                      dashboard?.stat_principal?.data[1]?.entete
                        ? dashboard?.stat_principal?.data[1]?.entete?.totalCouvertTable
                        : "0"
                    }}
                  </span>
                </div>
              </div>
              <div class="text-centers text-uppercase font-size-20 mt-2">
                <div>
                  {{ $t("content.pages.dashboard.cards.couvert_n_1.couvert_moyen") }} :
                  <span class="float-end">
                    {{
                      dashboard?.stat_principal?.data[1]?.entete
                        ? dashboard?.stat_principal?.data[1]?.entete?.totalCouvertMoyenneTable?.toFixed(2)
                        : "0.00"
                    }}
                    €
                  </span>
                </div>
              </div>
              <div class="text-centers text-uppercase font-size-20 mt-2">
                <div>
                  {{ $t("content.pages.dashboard.cards.couvert_n_1.ca_table") }} :
                  <span class="float-end">
                    {{
                      dashboard?.stat_principal?.data[1]?.entete
                        ? dashboard?.stat_principal?.data[1]?.entete?.totalChiffreCATable.toFixed(
                          2
                        )
                        : "0.00"
                    }}
                    €
                  </span>
                </div>
              </div>
              <div class="text-centers text-uppercase font-size-20 mt-2">
                <div>
                  {{ $t("content.pages.dashboard.cards.couvert_n_1.ca") }} :
                  <span class="float-end">
                    {{
                      dashboard?.stat_principal?.data[1]?.entete
                        ? dashboard?.stat_principal?.data[1]?.entete?.totalChiffreCA.toFixed(
                          2
                        )
                        : "0.00"
                    }}
                    €
                  </span>
                </div>
              </div>
              <div class="text-centers text-uppercase font-size-20 mt-2">
                <div>
                  <span>{{
                    $t("content.pages.dashboard.cards.couvert_n_1.reglements")
                  }}</span>
                  :
                  <span class="float-end">
                    {{
                      dashboard?.stat_principal?.data[1]?.reglements
                        ? dashboard?.stat_principal?.data[1]?.reglements?.TotalValeur?.toFixed(2)
                        : "0.00"
                    }}
                    €
                  </span>
                </div>
              </div>
              <div class="text-center text-uppercase hr-border">

                <h3>{{ $t("content.pages.dashboard.cards.couvert_n_1.type") }}</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  
    <div class="col-xl-6 col-lg-6 mb-3  d-flex align-items-stretch card-100">

      <weatherComponent :EmetteurVille="currentSite?.EmetteurVille
        ? currentSite?.EmetteurVille
        : currentSite?.sites?.length > 0
          ? currentSite?.sites[0]?.EmetteurVille
          : 'Paris'" />
    </div>
  </div>


</template>
<!-- eslint-disable -->
<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import LineChart from "@/components/chartVue/ChartLine.vue";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
export default {
  components: { LineChart, VueDatePicker },
  name: "mongoDATA",
  watch: {
    "datePicker.date.range"(newDate, oldDate) {
      if (newDate !== oldDate)
        this.callFunctions(
          this.datePicker.date.from,
          this.datePicker.date.to,
          this.datePicker.defaultDates.type
        );
    },
  },
  data() {
    return {
      apiKey: "d5d29bfed8ee0aa4b167f3bbd42204ce",
      dashboard: {
        meilleur_jour: [],
        stat_principal: [],
      },

    };
  },
  mounted() {
    this.callFunctions(
      this.datePicker.date.from,
      this.datePicker.date.to,
      this.datePicker.defaultDates.type
    );
    if (this.isAuthenticated && this.isRole == 'manager') {
      this.getMeilleurJourDuMois('month', 'month');
    }
  },
  created() { },
  unmounted() {
  },
  methods: {
    ...mapMutations(["setTicketID"]),
    ...mapActions([
      "BoardStatPrincipal",
      "BoardMeilleurJourDuMois",

    ]),


    callFunctions(from, to, type) {
      if (this.currentSite?._id) {
        let range = from
        this.getStatPrincipal(range, to);
      }
    },


    // statistique per 
    async getStatPrincipal(from, to) {
      this.dashboard.stat_principal = []
      let qte = "qte";
      let page = 1;
      let limit = 0;
      let self = this;
      this.dashboard.stat_principal = await this.BoardStatPrincipal({
        url: "board/stat-principal",
        page: page,
        limit: limit,
        range: self.datePicker.date.range,
        range_n: self.datePicker.date_n.range,
        vendeur: "vendeur",
        site: self.currentSite?._id,
        qte: qte,
        WhereLibInArray: [
          { LibValeur: 'vendeur', LibKey: 'LibVendeur' },
          { LibValeur: 'caisse', LibKey: 'LibCaisse' },
          { LibValeur: ['VENTE', 'TRANSFERT', 'ANNULATION'], LibKey: 'Operation' },
        ]
      });
    },
    async getMeilleurJourDuMois(from, to) {
      if (this.currentSite?._id) {
        let qte = "qte";
        let page = 1;
        let limit = 0; // select first element
        let self = this;
        this.dashboard.meilleur_jour = await this.BoardMeilleurJourDuMois({
          url: "board/meilleur-jour-du-mois",
          page: page,
          limit: limit,
          range: self?.datePicker?.defaultDates?.list[4] || null,
          vendeur: "vendeur",
          site: self.currentSite?._id,
          qte: qte,
          WhereLibInArray: [
            { LibValeur: 'vendeur', LibKey: 'LibVendeur' },
            { LibValeur: 'caisse', LibKey: 'LibCaisse' },
            { LibValeur: null, LibKey: 'Operation' },
          ]
        });
      }
    },






  },
  computed: {
    ...mapGetters({

      isRequestBestDay: "isRequestBestDay",
      isRequestStatPrincipal: "isRequestStatPrincipal",
      isAuthenticated: "isAuthenticated",
      isRole: "isRole",
      res: "StateRes",
      currentSite: "currentSite",
      listChiffreAffaires: "listChiffreAffaires",
      TimeDebutCalclule: "TimeDebutCalclule"
    }),
    ...mapState(["ticket_id", "datePicker"]),


  },
};
</script>
<!-- eslint-disable -->

<style>
.selected-default-dates {
  background: #084593 !important;
  color: #fff !important;
}

.card-100 .card {
  width: 100%;
}

.card .card-title {
  margin-bottom: 0px !important;
}



.type_ca span.brut {
  border-left: 1px solid #d9d9d9;
  border-right: 0px solid #d9d9d9;
  border-radius: 2px 0 0 2px;
}

.type_ca span.net {
  border-left: 0px solid #d9d9d9;
  border-right: 1px solid #d9d9d9;
  border-radius: 0 2px 2px 0;
}

.btn-event-export {
  border: 1px solid #bdbdbd;
  padding: 5px;
  text-align: center;
  background: #fff;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}

.statistique-font-size {
  font-size: 20px;
}

.statistique {
  font-weight: 600;
  margin-top: 10px !important;
  display: block;
  margin: 0px !important;
}

.height-100 {
  height: 100%;
}

.geolocation.latlong {
  font-size: 15px;
}

.font-size-20 {
  font-size: 14px;
}

.card-body {
  padding: 0.8rem 0.8rem !important;
}


.percentage svg {
  margin-right: 4px;
}

.percentage .red {
  color: red !important;
}

.percentage .green,
.percentage {
  color: #14e114;
}

.percentage {
  width: 90px;
  display: inline-block;
}
</style>
