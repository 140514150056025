<!-- eslint-disable -->
<template>
  <div class="d-flex align-items-center justify-content-center" v-if="show">
    <div class="row w-100 mx-0 auth-page">
      <div class="col-md-12 col-xl-12 mx-auto">
        <div class="card">
          <div class="row">
            <div class="col-md-12 stretch-card">
              <div class="card">
                <div class="card-body">
                  <div class="noble-ui-logo d-block mb-4">Modifier<span>&nbsp;le
                      restaurant </span></div>

                  <form @submit.prevent="SubmitData">
                    <div class="row">
                      <div class="col-sm-6 mb-3">
                        <div class=" ">
                          <label class="form-label">Nom du Restaurant</label>
                          <input type="text" readonly class="form-control" placeholder="Nom du Restaurant"
                            v-model="form.name" />
                        </div>
                        <div class="errors-response" v-if="v$.form.name.$error">
                          <p v-if="!v$.form.name.required.$response">{{ $t('validation.required') }}
                          </p>
                          <p class="error" v-if="!v$.form.name.minLength.$response">
                            {{ $t('validation.min.string', {
                              attribute: 'name', min: v$.form.name.minLength.$params.min
                            }) }}
                          </p>
                          <p class="error" v-if="!v$.form.name.maxLength.$response">
                            {{ $t('validation.max.string', {
                              attribute: 'name', max: v$.form.name.maxLength.$params.max
                            }) }}
                          </p>
                        </div>
                      </div>

                      <div class="col-sm-6 mb-3">
                        <div class=" ">
                          <label class="form-label">Siret</label>
                          <input type="text" readonly class="form-control" placeholder="Siret"
                            v-model="form.EmetteurSiret" />
                        </div>
                        <div class="errors-response" v-if="v$.form.EmetteurSiret.$error">
                          <p v-if="!v$.form.EmetteurSiret.required.$response">{{ $t('validation.required') }}
                          </p>
                          <p class="error" v-if="!v$.form.EmetteurSiret.minLength.$response">
                            {{ $t('validation.min.string', {
                              attribute: 'Siret', min: v$.form.EmetteurSiret.minLength.$params.min
                            }) }}
                          </p>
                          <p class="error" v-if="!v$.form.EmetteurSiret.maxLength.$response">
                            {{ $t('validation.max.string', {
                              attribute: 'Siret', max: v$.form.EmetteurSiret.maxLength.$params.max
                            }) }}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-sm-4 mb-3">
                        <div class=" ">
                          <label class="form-label">Societe</label>
                          <input type="text" class="form-control" placeholder="Societe" v-model="form.EmetteurSociete" />
                        </div>
                        <div class="errors-response" v-if="v$.form.EmetteurSociete.$error">
                          <p v-if="!v$.form.EmetteurSociete.required.$response">{{ $t('validation.required') }}
                          </p>
                          <p class="error" v-if="!v$.form.EmetteurSociete.minLength.$response">
                            {{ $t('validation.min.string', {
                              attribute: 'Societe', min: v$.form.EmetteurSociete.minLength.$params.min
                            }) }}
                          </p>
                          <p class="error" v-if="!v$.form.EmetteurSociete.maxLength.$response">
                            {{ $t('validation.max.string', {
                              attribute: 'Societe', max: v$.form.EmetteurSociete.maxLength.$params.max
                            }) }}
                          </p>
                        </div>
                      </div>

                      <div class="col-sm-4 mb-3">
                        <div class=" ">
                          <label class="form-label">Adresse</label>
                          <input type="text" class="form-control" placeholder="Adresse" v-model="form.EmetteurAdresse" />
                        </div>
                        <div class="errors-response" v-if="v$.form.EmetteurAdresse.$error">
                          <p v-if="!v$.form.EmetteurAdresse.required.$response">{{ $t('validation.required') }}
                          </p>
                          <p class="error" v-if="!v$.form.EmetteurAdresse.minLength.$response">
                            {{ $t('validation.min.string', {
                              attribute: 'Adresse', min: v$.form.EmetteurAdresse.minLength.$params.min
                            }) }}
                          </p>
                          <p class="error" v-if="!v$.form.EmetteurAdresse.maxLength.$response">
                            {{ $t('validation.max.string', {
                              attribute: 'Adresse', max: v$.form.EmetteurAdresse.maxLength.$params.max
                            }) }}
                          </p>
                        </div>
                      </div>

                      <div class="col-sm-4 mb-3">
                        <div class=" ">
                          <label class="form-label">Ville</label>
                          <input type="text" class="form-control" placeholder="Entrez la ville"
                            v-model="form.EmetteurVille" />
                        </div>
                        <div class="errors-response" v-if="v$.form.EmetteurVille.$error">
                          <p v-if="!v$.form.EmetteurVille.required.$response">{{ $t('validation.required') }}
                          </p>
                          <p class="error" v-if="!v$.form.EmetteurVille.minLength.$response">
                            {{ $t('validation.min.string', {
                              attribute: 'Ville', min: v$.form.EmetteurVille.minLength.$params.min
                            }) }}
                          </p>
                          <p class="error" v-if="!v$.form.EmetteurVille.maxLength.$response">
                            {{ $t('validation.max.string', {
                              attribute: 'Ville', max: v$.form.EmetteurVille.maxLength.$params.max
                            }) }}
                          </p>
                        </div>
                      </div>
                      <div class="col-sm-4 mb-3">
                        <div class=" ">
                          <label class="form-label">Code Postal</label>
                          <input type="text" class="form-control" placeholder="Entrez le Code Postal"
                            v-model="form.EmetteurCodePostal" />
                        </div>
                        <div class="errors-response" v-if="v$.form.EmetteurCodePostal.$error">
                          <p v-if="!v$.form.EmetteurCodePostal.required.$response">{{ $t('validation.required') }}
                          </p>
                          <p class="error" v-if="!v$.form.EmetteurCodePostal.minLength.$response">
                            {{ $t('validation.min.string', {
                              attribute: 'Code postal', min: v$.form.EmetteurCodePostal.minLength.$params.min
                            }) }}
                          </p>
                          <p class="error" v-if="!v$.form.EmetteurCodePostal.maxLength.$response">
                            {{ $t('validation.max.string', {
                              attribute: 'Code postal', max: v$.form.EmetteurCodePostal.maxLength.$params.max
                            }) }}
                          </p>
                        </div>
                      </div>
                      <div class="col-sm-4 mb-3">
                        <div class=" ">
                          <label class="form-label">Pays</label>
                          <input type="text" class="form-control" placeholder="Pays" v-model="form.EmetteurPays" />
                        </div>
                        <div class="errors-response" v-if="v$.form.EmetteurPays.$error">
                          <p v-if="!v$.form.EmetteurPays.required.$response">{{ $t('validation.required') }}
                          </p>
                          <p class="error" v-if="!v$.form.EmetteurPays.minLength.$response">
                            {{ $t('validation.min.string', {
                              attribute: 'Pays', min: v$.form.EmetteurPays.minLength.$params.min
                            }) }}
                          </p>
                          <p class="error" v-if="!v$.form.EmetteurPays.maxLength.$response">
                            {{ $t('validation.max.string', {
                              attribute: 'Pays', max: v$.form.EmetteurPays.maxLength.$params.max
                            }) }}
                          </p>
                        </div>
                      </div>
                      <div class="col-sm-4 mb-3">
                        <div class=" ">
                          <label class="form-label">Numér TVA</label>
                          <input type="text" class="form-control" placeholder="NuméroTVA" v-model="form.EmetteurNumTVA" />
                        </div>
                        <div class="errors-response" v-if="v$.form.EmetteurNumTVA.$error">
                          <p v-if="!v$.form.EmetteurNumTVA.required.$response">{{ $t('validation.required') }}
                          </p>
                          <p class="error" v-if="!v$.form.EmetteurNumTVA.minLength.$response">
                            {{ $t('validation.min.string', {
                              attribute: 'Numéro de TVA', min: v$.form.EmetteurNumTVA.minLength.$params.min
                            }) }}
                          </p>
                          <p class="error" v-if="!v$.form.EmetteurNumTVA.maxLength.$response">
                            {{ $t('validation.max.string', {
                              attribute: 'Numéro de TVA', max: v$.form.EmetteurNumTVA.maxLength.$params.max
                            }) }}
                          </p>
                        </div>
                      </div>
                      <div class="col-sm-4 mb-3">
                        <div class=" ">
                          <label class="form-label">Etablissement</label>
                          <input type="text" class="form-control" placeholder="Etablissement"
                            v-model="form.EmetteurEtablissement" />
                        </div>
                        <div class="errors-response" v-if="v$.form.EmetteurEtablissement.$error">
                          <p v-if="!v$.form.EmetteurEtablissement.required.$response">{{ $t('validation.required') }}
                          </p>
                          <p class="error" v-if="!v$.form.EmetteurEtablissement.minLength.$response">
                            {{ $t('validation.min.string', {
                              attribute: 'Etablissement', min: v$.form.EmetteurEtablissement.minLength.$params.min
                            }) }}
                          </p>
                          <p class="error" v-if="!v$.form.EmetteurEtablissement.maxLength.$response">
                            {{ $t('validation.max.string', {
                              attribute: 'Etablissement', max: v$.form.EmetteurEtablissement.maxLength.$params.max
                            }) }}
                          </p>
                        </div>
                      </div>
                      <div class="col-md-12 mb-3">
                        <div class=" w-50 float-end">
                          <div class="form-label">&nbsp;</div>
                          <button type="submit" class="btn btn-primary submit w-100">
                            Modifier
                          </button>
                        </div>
                      </div>
                    </div>

                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<!-- eslint-disable -->
<script>
import { mapActions, mapGetters } from "vuex";
import useVuelidate from "@vuelidate/core";
import { required, minLength, maxLength, email, requiredIf, decimal, numeric, helpers, min } from "@vuelidate/validators";
const { withParams } = helpers;
import { alertSwal } from "@/Utils/globale.js";

export default {
  name: "AddrestaurantView",
  computed: {
    ...mapGetters({ isRole: "isRole", isAuthenticated: "isAuthenticated", BaseUrl: "StateBase", StateUser: "StateUser", }),
  },
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    return {
      form: {
        name: {
          required,
          minLength: minLength(1),
          maxLength: maxLength(100)
        },
        EmetteurSociete: {
          required,
          minLength: minLength(1),
          maxLength: maxLength(100)
        },
        EmetteurAdresse: {
          required,
          minLength: minLength(1),
          maxLength: maxLength(500)
        },
        EmetteurVille: {
          required,
          minLength: minLength(1),
          maxLength: maxLength(100)
        },
        EmetteurCodePostal: {
          required,
          minLength: minLength(1),
          maxLength: maxLength(100)
        },
        EmetteurEtablissement: {
          required,
          minLength: minLength(1),
          maxLength: maxLength(500)
        },
        EmetteurNumTVA: {
          required,
          minLength: minLength(1),
          maxLength: maxLength(200)
        },
        EmetteurSiret: {
          required,
          minLength: minLength(1),
          maxLength: maxLength(250)
        },
        EmetteurPays: {
          required,
          minLength: minLength(1),
          maxLength: maxLength(250)
        },
      }
    };
  },
  mounted() {
    if (this.$route.params.id) {
      const get = async () => {
        try {
          this.restaurant = await this.getRestaurantById(this.$route.params.id);
          this.form.id = this.restaurant._id;
          this.form.name = this.restaurant?.name;
          this.form.LibSite = this.restaurant?.LibSite;
          this.form.EmetteurSociete = this.restaurant?.EmetteurSociete;
          this.form.EmetteurAdresse = this.restaurant?.EmetteurAdresse;
          this.form.EmetteurVille = this.restaurant?.EmetteurVille;
          this.form.EmetteurCodePostal = this.restaurant?.EmetteurCodePostal;
          this.form.EmetteurEtablissement = this.restaurant?.EmetteurEtablissement;
          this.form.EmetteurNumTVA = this.restaurant?.EmetteurNumTVA;
          this.form.EmetteurSiret = this.restaurant?.EmetteurSiret;
          this.form.EmetteurPays = this.restaurant?.EmetteurPays;



          this.show = true;
        } catch (error) {
          if (error) {
            this.show = false;
            console.log(error);
            //this.showAlert("Forbidden resource");
          }
        }
      };
      if (this.isAuthenticated && this.$store.state.roles.includes(this.isRole))
        get();
    }
  },
  data() {
    return {
      users: [],
      form: {
        id: "",
        name: "",
        LibSite: "",
        EmetteurSociete: "",
        EmetteurAdresse: "",
        EmetteurVille: "",
        EmetteurCodePostal: "",
        EmetteurEtablissement: "",
        EmetteurNumTVA: "",
        EmetteurSiret: "",
        EmetteurPays: "",
      },
      show: false,
    };
  },
  methods: {
    onChange(event) {
      this.manager = event.target.value;
    },

    ...mapActions(["mangerWithAssociatedRestaurants", "updateRestaurant", "getRestaurantById"]),
    async SubmitData() {
      this.v$.$touch();
      if (
        !this.v$.$invalid
      ) {
        const restaurant = {
          id: this.restaurant._id,
          name: this.restaurant?.name,
          LibSite: this.restaurant?.name,
          EmetteurSociete: this.form.EmetteurSociete,
          EmetteurAdresse: this.form.EmetteurAdresse,
          EmetteurVille: this.form.EmetteurVille,
          EmetteurCodePostal: this.form.EmetteurCodePostal,
          EmetteurEtablissement: this.form.EmetteurEtablissement,
          EmetteurNumTVA: this.form.EmetteurNumTVA,
          EmetteurSiret: this.restaurant?.EmetteurSiret,
          EmetteurPays: this.form.EmetteurPays,
        };
        try {
          await this.updateRestaurant(restaurant);
          await this.mangerWithAssociatedRestaurants({
            url: "manager/associated-restaurants",
            userID: this.StateUser?._id
          })
          alertSwal('success', this.$t('alertswal.updated', { champ: 'Restaurant' }))
          window.location.href = "/details-restaurant-manager/" + this.restaurant._id

        } catch (error) {
          if (error.response.status === 403) {
            alertSwal("Forbidden");
            return;
          } else
            alertSwal("error", "Il y a un problème, veuillez réessayer s'il vous plaît");
        }
      } else {
        console.log('Form has errors. Please fix them.');
        return;
      }
    },
  },
};
</script>
