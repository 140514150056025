<!-- eslint-disable -->
<template>
  <div class="d-flex align-items-center justify-content-center" v-if="show">
    <div class="row w-100 mx-0 auth-page">
      <div class="col-md-12 col-xl-12 mx-auto">
        <div class="card">
          <div class="row">
            <div class="col-md-12 stretch-card">
              <div class="card">
                <div class="card-body">
                  <a href="#" class="noble-ui-logo d-block mb-2">Inscrire<span>&nbsp;le restaurant</span></a>
                  <h5 class="text-muted fw-normal mb-4">
                    Créer un nouveau restaurant.
                  </h5>
                  <form @submit.prevent="SubmitData">
                    <div class="row">
                      <div class="col-sm-12">
                        <div class="mb-3">
                          <label class="form-label">Nom du Restaurant</label>
                          <input type="text" class="form-control" placeholder="Nom du Restaurant" v-model="name" />
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-sm-4">
                        <div class="mb-3">
                          <label class="form-label">Societe</label>
                          <input type="text" class="form-control" placeholder="Societe" v-model="EmetteurSociete" />
                        </div>
                      </div>

                      <div class="col-sm-4">
                        <div class="mb-3">
                          <label class="form-label">Adresse</label>
                          <input type="text" class="form-control" placeholder="Adresse" v-model="EmetteurAdresse" />
                        </div>
                      </div>

                      <div class="col-sm-4">
                        <div class="mb-3">
                          <label class="form-label">Ville</label>
                          <input type="text" class="form-control" placeholder="Entrez la ville"
                            v-model="EmetteurVille" />
                        </div>
                      </div>
                      <div class="col-sm-4">
                        <div class="mb-3">
                          <label class="form-label">Code Postal</label>
                          <input type="text" class="form-control" placeholder="Entrez le Code Postal"
                            v-model="EmetteurCodePostal" />
                        </div>
                      </div>
                      <div class="col-sm-4">
                        <div class="mb-3">
                          <label class="form-label">Pays</label>
                          <input type="text" class="form-control" placeholder="Pays" v-model="EmetteurPays" />
                        </div>
                      </div>
                      <div class="col-sm-4">
                        <div class="mb-3">
                          <label class="form-label">Numér TVA</label>
                          <input type="text" class="form-control" placeholder="NuméroTVA" v-model="EmetteurNumTVA" />
                        </div>
                      </div>

                      <div class="col-md-6">
                        <div class="mb-3">
                          <label class="form-label">Siret</label>
                          <input type="text" class="form-control" placeholder="Siret" v-model="EmetteurSiret" />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="mb-3">
                          <label class="form-label">Etablissement</label>
                          <input type="text" class="form-control" placeholder="Etablissement"
                            v-model="EmetteurEtablissement" />
                        </div>
                      </div>
                      <div class="col-md-12 mt-3 mb-3">
                        <div class="">
                          <h4>Abonnement : </h4>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="mb-3">
                          <label class="form-label">Date début</label>
                          <VueDatePicker locale="fr" v-model="dateDebut"
                            :action-row="{ showSelect: false, showCancel: false }" close-on-scroll auto-apply
                            show-now-button date-picker />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="mb-3">
                          <label class="form-label">Date fin</label>
                          <VueDatePicker locale="fr" v-model="dateFin"
                            :action-row="{ showSelect: false, showCancel: false }" close-on-scroll auto-apply
                            show-now-button date-picker />
                        </div>
                      </div>
                      <div class="col-sm-4">
                        <div class="mb-3">
                          <div class="form-label">&nbsp;</div>
                          <button type="submit" class="btn btn-primary submit">
                            Créer
                          </button>
                        </div>
                      </div>
                    </div>

                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<!-- eslint-disable -->
<script>
import { mapActions, mapGetters } from "vuex";
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

export default {
  name: "AddrestaurantView",
  components: { VueDatePicker },
  computed: {
    ...mapGetters({ isRole: "isRole", isAuthenticated: "isAuthenticated", User: "StateUser" }),
  },
  mounted() {
    const get = async () => {
      try {
        this.show = true;
      } catch (error) {
        if (error) {
          this.show = false;
          this.showAlert("Forbidden resource");
        }
      }
    };
    if (this.isAuthenticated && this.$store.state.roles.includes(this.isRole))
      get();
  },
  data() {
    return {
      users: [],
      name: "",
      EmetteurSociete: "",
      EmetteurAdresse: "",
      EmetteurVille: "",
      EmetteurCodePostal: "",
      EmetteurEtablissement: "",
      EmetteurNumTVA: "",
      EmetteurSiret: "",
      EmetteurPays: "",
      abonnements: [],
      dateDebut: null,
      dateFin: null,
      show: false,
    };
  },
  methods: {
    showSuccess() {
      this.$swal({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        icon: "success",
        title: "edit is successfully done",
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
    },
    showAlert(error) {
      this.$swal({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        icon: "error",
        title: error,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
    },
    ...mapActions(["createRestaurant"]),
    async SubmitData() {
      this.dateDebut = this.$moment(String(this.dateDebut)).format("MM-DD-YYYY");
      this.dateFin = this.$moment(String(this.dateFin)).format("MM-DD-YYYY");
      const restaurant = {
        name: this.name,
        LibSite: this.name,
        EmetteurSociete: this.EmetteurSociete,
        EmetteurAdresse: this.EmetteurAdresse,
        EmetteurVille: this.EmetteurVille,
        EmetteurCodePostal: this.EmetteurCodePostal,
        EmetteurEtablissement: this.EmetteurEtablissement,
        EmetteurNumTVA: this.EmetteurNumTVA,
        EmetteurSiret: this.EmetteurSiret,
        EmetteurPays: this.EmetteurPays,
        abonnements: [{ dateDebut: this.dateDebut, dateFin: this.dateFin, motif: '', closed: false }],
        //abonnements: []
        userEmail: User?.email ?? null
      };
      try {
        if (
          this.name === ""
          //||
          //this.manager === "" ||
          // this.EmetteurSociete === "" ||
          // this.EmetteurAdresse === "" ||
          // this.EmetteurVille === "" ||
          // this.EmetteurEtablissement === "" ||
          // this.EmetteurNumTVA === "" ||
          // this.EmetteurSiret === "" ||
          // this.EmetteurPays === ""
        ) {
          this.showAlert("Veuillez remplir tous les champs");
        } else {
          await this.createRestaurant(restaurant);
          // this.$router.push({ name: "restaurants" });
          // this.showSuccess();
        }
      } catch (error) {
        console.log(error);
        if (error.response.status === 403) {
          this.showAlert("Forbidden");
          return;
        }
        this.showAlert("Il y a un problème, veuillez réessayer s'il vous plaît");
      }
    },
  },
};
</script>
