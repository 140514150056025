<!-- eslint-disable -->
<template>
  <div class="modal fade" id="modalEdit" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-labelledby="modalEditLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <form class="forms-sample" @submit.prevent="SubmitData">
          <div class="modal-body">
            <div class="row" v-if="show">
              <div class="col-md-12 ps-md-0">
                <div class="auth-form-wrapper px-4 py-2">
                  <div class="row">
                    <div class="col-md-12">
                      <label for="libel" class="form-label">Status</label>
                      <div class="d-flex justify-content align-items-end flex-wrap grid-margin">
                        <span class="w-50 type_ca float-end">
                          <span @click="UpdateEtat(1)" type="button"
                            class="w-100 btn btn-info btn-icon-text me-3 mb-3 mb-md-0 "
                            :class="{ 'selected-default-dates': form.isActive }">Activer</span>
                        </span>
                        <span class="w-50 type_ca float-end">
                          <span @click="UpdateEtat(0)" type="button"
                            class="w-100 btn btn-info btn-icon-text me-3 mb-3 mb-md-0 "
                            :class="{ 'selected-default-dates': !form.isActive }">Désactiver</span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Annuler</button>
            <button type="submit" class="btn btn-primary" data-bs-dismiss="modal">Modifier</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<!-- eslint-disable -->
<script>
import { mapGetters, mapActions } from "vuex";
import useVuelidate from "@vuelidate/core";
import { required, minLength, maxLength, email, requiredIf, decimal, numeric, helpers, min } from "@vuelidate/validators";
const { withParams } = helpers;
import { alertSwal } from "@/Utils/globale.js";

export default {
  props: ['vendeur'],
  computed: {
    ...mapGetters({ isRole: "isRole", isAuthenticated: "isAuthenticated", Username: "StateUsername", BaseUrl: "StateBase" }),
  },
  watch: {
    vendeur(newVal, oldVal) {
      this.fetchData()
    }
  },
  data() {
    return {
      form: {
        id: "",
        LibVendeur: "",
        isActive: false
      }
    };
  },

  methods: {
    ...mapActions([
      "updateEtatVendeur"
    ]),
    UpdateEtat(status) {
      this.form.isActive = status
    },
    fetchData() {
      this.form.id = this.vendeur?._id;
      this.form.LibVendeur = this.vendeur?.LibVendeur;
      this.form.isActive = this.vendeur?.isActive;

      this.show = true
    },
    emptyState() {
      this.form.id = ""
      this.form.LibVendeur = ""
      this.form.isActive = false
      this.show = true
    },


    async SubmitData() {
      const vendeur = {
        ids: [this.form.id],
        isActive: this.form.isActive,
      };
      try {
        await this.updateEtatVendeur(vendeur);
        this.emptyState()
        this.$emit('getListVendeursData');
        alertSwal('success', this.$t('alertswal.updated', { champ: 'Etat de vendeur' }))
      } catch (error) {
        if (error.response.status === 403) {
          alertSwal('error', this.$t('forbidden'))
          return;
        } else if (error.response.status === 404) {
          alertSwal('error', "error")
          return;
        }
        alertSwal("error", "Il y a un problème, veuillez réessayer s'il vous plaît");
      }
    },
  },
};
</script>
<!-- eslint-disable -->
<style scoped>
.btn-info {
  height: 34px !important;
}
</style>
