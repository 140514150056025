<!-- eslint-disable -->
<template>
  <div>
    <div>
      <span class="generate-to-file pdf" @click="generatePdf"><i class="far fa-file-pdf"></i></span>
    </div>
  </div>
</template>
<!-- eslint-disable -->
<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import { Division } from "@/Utils/globale.js";

//import Vue3Html2pdf from "vue3-html2pdf";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";

export default {
  props: ["dateFrom", "dateTo", "exportTo"],
  components: {
    //Vue3Html2pdf,
  },
  data() {
    return {
      clicked: false,
    };
  },
  methods: {
    async generatePdf() {

      var doc = new jsPDF({
        orientation: "p",
        unit: "mm",
        format: "a4",
        putOnlyUsedFonts: true,
        precision: 20,
      });

      let startY = 15;
      doc.setFont("helvetica", "normal", "bold");
      doc.setFontSize(11);
      var pageWidth =
        doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
      var text = "RÉSULTAT ARTICLE";
      doc.text(text, pageWidth / 2, startY, { align: "center" });


      let startYY = 15
      startYY += 15;
      doc.setFont("helvetica", "normal", "bold");
      doc.setFontSize(9);
      doc.text("DATE D'ÉDITION", 132, startYY, "left");
      doc.setFont("helvetica", "normal", "normal");
      doc.setFontSize(9);
      doc.text(
        " : " + this.$moment(String(new Date())).utc().format("MM-DD-YYYY HH:mm:ss"),
        pageWidth - 50,
        startYY,
        "left"
      );

      startYY += 6;
      doc.setFont("helvetica", "normal", "bold");
      doc.setFontSize(9);
      doc.text("DATE DE DEBUT", 132, startYY, "left");
      doc.setFont("helvetica", "normal", "normal");
      doc.setFontSize(9);
      doc.text(" : " + this.dateFrom, pageWidth - 50, startYY, "left");

      startYY += 6;
      doc.setFont("helvetica", "normal", "bold");
      doc.setFontSize(9);
      doc.text("DATE DE FIN", 132, startYY, "left");
      doc.setFont("helvetica", "normal", "normal");
      doc.setFontSize(9);
      doc.text(" : " + this.dateTo, pageWidth - 50, startYY, "left");






      startY += 15;
      doc.setFont("helvetica", "normal", "bold");
      doc.setFontSize(9);
      doc.text("SITE", 15, startY, "left");
      doc.setFont("helvetica", "normal", "normal");
      doc.setFontSize(9);
      doc.text(
        " : " + this.currentSite?.LibSite,
        44,
        startY,
        "left"
      );
      startY += 6;
      doc.setFont("helvetica", "normal", "bold");
      doc.setFontSize(9);
      doc.text("NOM SOCIÉTÉ", 15, startY, "left");
      doc.setFont("helvetica", "normal", "normal");
      doc.setFontSize(9);
      doc.text(
        " : " + this.currentSite?.EmetteurSociete,
        44,
        startY,
        "left"
      );
      startY += 6;
      doc.setFont("helvetica", "normal", "bold");
      doc.setFontSize(9);
      doc.text("NUMÉRO TVA", 15, startY, "left");
      doc.setFont("helvetica", "normal", "normal");
      doc.setFontSize(9);
      doc.text(
        " : Siret " + this.currentSite?.EmetteurSiret,
        44,
        startY,
        "left"
      );



      startY += 18;
      doc.setFont("helvetica", "normal", "bold");
      doc.setFontSize(16);
      pageWidth =
        doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
      text = "Total des ventes : " + this.exportTo.ca + " €";
      doc.text(text, pageWidth / 2, startY, { align: "center" });





      let TextY = 70;
      let finalY = 76;

      //await Promise.all(this.exportTo.data.map(async (el) => {
      var result = {};
     
      this.exportTo.data[0].map((el) => {
        doc.setFont("helvetica", "normal", "bold");
        doc.setFontSize(9);
        doc.text(el._id, 15, TextY, "left");
        var element = {};
        element[el._id] = [];
        el.LibFamilles.map((LibFamille) => {
          element[el._id].push([
            LibFamille.libelle,
            LibFamille.QuantityLibelle.toString(),
            Division(LibFamille.QuantityLibelle * 100, el.QuantityFamille) + " %",
            LibFamille.PrixTotalNetLibelle.toFixed(2).toString() + " €",
            Division(LibFamille.PrixTotalNetLibelle * 100, el.PrixTotalNetFamille) + " %",
          ]);
        });
        result[el._id] = [];
        result[el._id].push([
          el._id,
          el.QuantityFamille.toString(),
          el.QteRatioFamille.toFixed(2).toString() + " %",
          el.PrixTotalNetFamille.toFixed(2).toString() + " €",
          el.PrixRatioFamille.toFixed(2).toString() + " %",
        ]);
        autoTable(doc, {
          margin: { top: 30 },
          startY: finalY,
          pageBreak: "auto",
          theme: "striped",
          headStyles: {
            fillColor: [29, 30, 34],
            textColor: [255, 255, 255],
            cellPadding: 3,
          },
          footStyles: {
            fillColor: [102, 102, 102],
            textColor: [255, 255, 255],
            cellPadding: 2,
          },
          bodyStyles: { cellPadding: 2 },
          head: [this.exportTo.columns],
          body: element[el._id],
          foot: result[el._id],
        });

        TextY = doc.autoTable.previous
          ? doc.autoTable.previous.finalY + 20
          : TextY;
        finalY = doc.autoTable.previous
          ? doc.autoTable.previous.finalY + 26
          : finalY;
      });

      doc.setFont("helvetica", "normal", "bold");
      doc.setFontSize(16);
      pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
      text = "Total des ventes : " + this.exportTo.ca + " €";
      doc.text(text, pageWidth / 2, finalY, { align: "center" });


      doc.save(this.exportTo.fileName);
    },
  },
  mounted() { },
  computed: {
    ...mapGetters({ isAuthenticated: "isAuthenticated", res: "StateRes", currentSite: "currentSite" }),

  }
};
</script>
