<!-- eslint-disable -->
<template>


  <div class="row">


    <div class="col-md-12 grid-margin stretch-card">
      <div class="card">
        <SectionLoaderView v-if="loadingRequest?.BoardStatistiquesServeurs" />
        <div class="card-header">
          <h3 class="card-title text-left">Statistiques des vendeurs</h3>

        </div>
        <div v-if="dashboard.statistiques_vendeurs && dashboard.statistiques_vendeurs.length > 0" class="card-body">
          <div class="table-responsive">
            <DataTable showGridlines stripedRows :value="dashboard.statistiques_vendeurs">
              <Column field="_id" header="Serveur" sortable> </Column>
              <Column field="TotalCouverts" header="Nb couverts" sortable>
                <template #body="slotProps">
                  {{ slotProps?.data?.TotalCouverts }}
                </template>
              </Column>
              <Column field="totalMontantTTC" header="CA (€)" sortable>
                <template #body="slotProps">
                  {{ slotProps?.data?.totalMontantTTC?.toFixed(2) }} €
                </template>
              </Column>
              <Column field="totalValeur" header="Encaisements (€)" sortable>
                <template #body="slotProps">
                  {{ slotProps?.data?.totalValeur?.toFixed(2) }} €
                </template>
              </Column>
            </DataTable>
          </div>
        </div>
        <div v-else class="card-body d-flex align-items-center justify-content-center">
          <datanotfound :text="$t(`content.message.datanotfound`)" />
        </div>
      </div>
    </div>
  </div>
</template>
<!-- eslint-disable -->
<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import LineChart from "@/components/chartVue/ChartLine.vue";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
export default {
  components: { LineChart, VueDatePicker },
  name: "mongoDATA",
  watch: {
    "datePicker.date.range"(newDate, oldDate) {
      if (newDate !== oldDate)
        this.callFunctions(
          this.datePicker.date.from,
          this.datePicker.date.to,
          this.datePicker.defaultDates.type
        );
    },
  },
  data() {
    return {


      dashboard: {


        statistiques_vendeurs: [],

      },


    };
  },
  mounted() {
    this.callFunctions(
      this.datePicker.date.from,
      this.datePicker.date.to,
      this.datePicker.defaultDates.type
    );

  },
  created() { },
  unmounted() {
  },
  methods: {
    ...mapMutations(["setTicketID"]),
    ...mapActions([
      "BoardStatistiquesServeurs"

    ]),


    callFunctions(from, to, type) {
      if (this.currentSite?._id) {
        let range = from

        this.getStatistiquesServeurs(range, to);
      }
    },

    async getStatistiquesServeurs(from, to) {
      let qte = "qte";
      let page = 1;
      let limit = 1;
      let self = this;
      this.dashboard.statistiques_vendeurs = await this.BoardStatistiquesServeurs({
        url: "board/serveurs",
        page: page,
        limit: limit,
        range: self.datePicker.date.range,
        vendeur: "vendeur",
        site: self.currentSite?._id,
        qte: qte,
        WhereLibInArray: [
          { LibValeur: 'vendeur', LibKey: 'LibVendeur' },
          { LibValeur: 'caisse', LibKey: 'LibCaisse' },
          { LibValeur: ['VENTE', 'TRANSFERT', 'ANNULATION'], LibKey: 'Operation' },
        ]
      });
    },




  },
  computed: {
    ...mapGetters({

      isAuthenticated: "isAuthenticated",
      isRole: "isRole",
      res: "StateRes",
      currentSite: "currentSite",
      listChiffreAffaires: "listChiffreAffaires",
      TimeDebutCalclule: "TimeDebutCalclule"
    }),
    ...mapState(["ticket_id", "datePicker", "loadingRequest"]),
  },
};
</script>
<!-- eslint-disable -->

<style>
.selected-default-dates {
  background: #084593 !important;
  color: #fff !important;
}

.card-100 .card {
  width: 100%;
}

.card .card-title {
  margin-bottom: 0px !important;
}

.type_ca span.brut {
  border-left: 1px solid #d9d9d9;
  border-right: 0px solid #d9d9d9;
  border-radius: 2px 0 0 2px;
}

.type_ca span.net {
  border-left: 0px solid #d9d9d9;
  border-right: 1px solid #d9d9d9;
  border-radius: 0 2px 2px 0;
}

.btn-event-export {
  border: 1px solid #bdbdbd;
  padding: 5px;
  text-align: center;
  background: #fff;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}

.statistique-font-size {
  font-size: 20px;
}

.statistique {
  font-weight: 600;
  margin-top: 10px !important;
  display: block;
  margin: 0px !important;
}

.height-100 {
  height: 100%;
}

.geolocation.latlong {
  font-size: 15px;
}

.font-size-20 {
  font-size: 14px;
}

.card-body {
  padding: 0.8rem 0.8rem !important;
}


.percentage svg {
  margin-right: 4px;
}

.percentage .red {
  color: red !important;
}

.percentage .green,
.percentage {
  color: #14e114;
}

.percentage {
  width: 90px;
  display: inline-block;
}
</style>
