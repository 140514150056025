<!-- eslint-disable -->
<template>
    <div class="d-flex align-items-center justify-content-center">
        <div class="row w-100 mx-0 auth-page">
            <div class="col-md-12 col-xl-12 mx-auto">
                <div class="card">
                    <div class="row">
                        <div class="col-md-12 stretch-card">
                            <div class="card">
                                <div class="card-body">
                                    <a href="#" class="noble-ui-logo d-block mb-2">Paramètres </a>
                                    <form @submit.prevent="SubmitData">
                                        <div class="row">
                                            <div class="col-sm-6">
                                                <div class="mb-3">
                                                    <label for="TimeDebut" class="form-label">Heure de début de calcul
                                                        du
                                                        ticket</label>
                                                    <VueDatePicker locale="fr" v-model="TimeDebut"
                                                        :action-row="{ showSelect: false, showCancel: false }"
                                                        close-on-scroll auto-apply show-now-button time-picker />


                                                </div>
                                            </div>
                                            <div class="col-sm-6">
                                                <div class="mb-3">
                                                    <label for="TimeDebut" class="form-label"> Heure de fin de calcul du
                                                        ticket</label>
                                                    <input type="text" readonly v-model="TimeFin" class="form-control">
                                                    <!-- <VueDatePicker locale="fr" v-model="TimeFin"
                                                        :action-row="{ showSelect: false, showCancel: false }"
                                                        close-on-scroll auto-apply show-now-button time-picker /> -->
                                                </div>
                                            </div>
                                            <!-- <div class="col-sm-6">
                                                <div class="mb-3">
                                                    <label class="form-label">Logo de votre société :</label>
                                                    <div class="row">
                                                        <div class="col-md-8">
                                                            <FileUpload mode="basic" accept="image/*" :maxFileSize="1000000"
                                                                :customUpload="true" @select="uploadImageLogo" />
                                                        </div>
                                                        <div class="col-md-4">

                                                            <div class="m-auto col-sm-1">
                                                                 <div class="d-flex align-items-center">
                                                                    <img style="width: 180px;" :src="logo" alt="logo" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> -->
                                        </div>
                                        <!-- Row -->
                                        <div class="row mt-5 mb-2">
                                            <div class="col-sm-12 ">
                                                <div class="mb-3 w-100 d-flex align-items-center">
                                                    <button type="submit" class="w-50 btn btn-primary submit">
                                                        Enregistrer
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- Row -->
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<!-- eslint-disable -->
<script>
import { mapGetters, mapActions } from "vuex";
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
export default {
    components: { VueDatePicker },
    name: "EditprofileView",
    computed: {
        ...mapGetters({ isRole: "isRole", isAuthenticated: "isAuthenticated", User: "StateUser", BaseUrl: "StateBase", currentSite: "currentSite", parametrages: "StateParametrages" }),
    },
    mounted() {
        if (this.isAuthenticated && this.isRole == 'manager')
            this.getParametrages()
    },
    data() {
        return {
            logo: "",
            logoFile: null,
            TimeDebut: null,
            TimeFin: null
        };
    },
    methods: {
        ...mapActions(["upload", "updateParametrages"]),
        getParametrages() {
            let debut = this.parametrages?.calculeTicket?.TimeDebut || null
            this.TimeFin = this.parametrages?.calculeTicket?.TimeFin || null
            this.logo = this.BaseUrl + "" + this.parametrages?.Logo || null
            if (debut != null) {
                const [hoursDebut, minutesDebut] = (debut.toString()).split(":");
                const dateDebut = new Date();
                dateDebut.setHours(parseInt(hoursDebut, 10));
                dateDebut.setMinutes(parseInt(minutesDebut, 10));
                this.TimeDebut = {
                    hours: dateDebut.getHours(),
                    minutes: dateDebut.getMinutes()
                }
            }
        },
        uploadImageLogo(event) {
            this.logoFile = event.files[0];
            if (event.files[0].objectURL) {
                this.logo = event.files[0].objectURL;
            }
        },
        async SubmitData() {
            // add more logic here 
            if (this.currentSite?._id) {
                const parametre = {
                    "SiteID": this.currentSite?._id,
                    "TimeDebut": this.TimeDebut?.hours + ":" + this.TimeDebut?.minutes,
                    "Logo": (this.logoFile) ? this.logoFile : null
                };
                try {
                    await this.updateParametrages(parametre);
                    this.getParametrages()
                } catch (error) {
                    if (error.response.status === 403) {
                        this.showAlert("Forbidden");
                        return;
                    }
                    this.showAlert("Il y a un problème, veuillez réessayer s'il vous plaît");
                }
            } else {
                this.showAlert("Selectionner votre restaurant");
            }
        },
    },
};
</script>
