<!-- eslint-disable -->
<template>
  <div class="d-flex align-items-center justify-content-center">
    <div class="row w-100 mx-0 auth-page">
      <div class="col-md-12 mb-3">
        <div class="noble-ui-logo d-block mb-2">
          Liste<span>&nbsp;des restaurants </span>
        </div>
      </div>
      <div class="col-md-12 col-xl-12 mx-auto">

        <div v-if="selectedStores?.length > 0" class="row mb-3">
          <div class="card ">
            <div class="body-card m-4">
              <div class="row">
                <div class="col-md-12 mb-3">
                  <p>
                    Voulez-vous définir ce restaurant comme sélectionné par défaut ?
                  </p>
                </div>
                <div class="col-md-6 mb-2">
                  <button type="button" @click="editDefaultStore(true)" class="btn btn-sm btn-primary w-100">
                    Oui, défault
                  </button>
                </div>
                <div class="col-md-6 mb-2">
                  <button type="button" @click="editDefaultStore(false)" class="btn btn-sm btn-danger w-100">
                    Non
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div class="row">

          <div v-for="(restaurant, index) in $store.state.managerWithStores?.associatedRestaurants" v-bind:key="index"
            class="col-md-4 col-xl-4 mb-4">
            <div class="usercard p-3" :class="{ 'selected-default-store': restaurant?.isDefault }">
              <div class="d-flex align-items-center">

                <div class=" w-100">
                  <div style="display: flex;justify-content: end;">
                    <input type="checkbox" v-model="selectedStores" :value="restaurant._id">
                  </div>
                  <h5 class="mb-0 mt-0">{{ restaurant.LibSite }}</h5>
                  <div class="mt-2   d-flex justify-content-between rounded  ">
                    <div class="d-flex flex-column">


                      <p class="addresse">
                        <b>Adresse</b> : {{ restaurant.EmetteurAdresse }}, {{ restaurant.EmetteurVille }}, {{
                        restaurant.EmetteurPays }},
                        {{
                        restaurant.EmetteurCodePostal }}
                      </p>
                      <p class="addresse">
                        <b>Societe</b> : {{ restaurant.EmetteurSociete }}
                      </p>
                    </div>

                  </div>
                  <div class="lastet-subscribe">

                    <!--<div v-if="restaurant?.abonnements?.length > 0">
                      <p>
                        <b>Date début</b> : {{ restaurant.abonnements[restaurant.abonnements.length - 1]?.dateDebut }}
                      </p>
                      <p>
                        <b>Date fin</b> : {{ restaurant.abonnements[restaurant.abonnements.length - 1]?.dateFin }}
                      </p>
                      <p v-if="!isSubscribreEnd(restaurant.abonnements[restaurant.abonnements.length - 1]?.dateFin)">
                        <b>
                          Abonnement :
                        </b>
                        Términer
                      </p>
                    </div>-->

                    <div v-if="restaurant?.latestAbonnemnt">
                      <p>
                        <b>Date début</b> :
                        {{ $dayjs(restaurant.latestAbonnemnt?.dateDebut).utc().format("DD/MM/YYYY HH:mm:ss") }}
                      </p>
                      <p>
                        <b>Date fin</b> :
                        {{ $dayjs(restaurant.latestAbonnemnt?.dateFin).utc().format("DD/MM/YYYY HH:mm:ss") }}
                      </p>
                    </div>
                    <div v-else>
                      <p>
                        <b>
                          Abonnement :
                        </b>
                        Términer
                      </p>
                    </div>

                  </div>
                  <div class="button mt-2 d-flex flex-row align-items-center">
                    <button title="Modification" type="button" @click="editRes(restaurant._id)"
                      class="btn btn-sm btn-primary w-100">
                      <i class="fas fa-edit"></i>
                    </button>
                    <button title="Infos sur ce restaurant" type="button" @click="voirInfo(restaurant._id)"
                      class="ml-3 btn btn-sm btn-success w-100 ">
                      <i class="fas fa-eye"></i>
                    </button>
                    <button type="button" @click="selectedSite(restaurant)" class="ml-3 btn btn-sm btn-secondary w-100 "
                      title="Consulter les détails du restaurant">
                      <i class="fas fa-chart-line"></i>
                    </button>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<!-- eslint-disable -->
<script>
///import { DeleteIcon } from "@vue-icons/feather";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    // DeleteIcon,
  },
  computed: {
    ...mapGetters({ isRole: "isRole", isAuthenticated: "isAuthenticated", User: "StateUser", ManagerWithStore: "StateManagerWithStore", }),
  },
  mounted() {
    const get = async () => {
      this.manager = this.$store.state.managerWithStores
    };
    if (this.isAuthenticated && this.$store.state.roles.includes(this.isRole))
      get();

  },
  data() {
    return {
      manager: [],
      show: false,
      selectedStores: []

    };
  },
  methods: {
    showSuccess() {
      this.$swal({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        icon: "success",
        title: "delete is successfully done",
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
    },
    showAlert(error) {
      this.$swal({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        icon: "error",
        title: error,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
    },
    ...mapActions([
      "selectedCurrentSite",
      "defaultStore"
    ]),
    async editRes(store_id) {
      try {
        this.$router.push({
          name: "modifier-restaurant-manager",
          params: { id: store_id },
        });
      } catch (error) {
        console.log(error);
        //this.showAlert("Forbidden resource");
      }
    },
    async voirInfo(store_id) {
      try {
        this.$router.push({
          name: "details-restaurant-manager",
          params: { id: store_id },
        });
      } catch (error) {
        this.showAlert("nothing to delete");
      }
    },
    isSubscribreEnd(dateFin) {
      const currentDate = this.$moment()
      //var fin = this.$moment(dateFin, "MM-DD-YYYY");
      var fin = this.$moment(dateFin).format("YYYY-MM-DD");
      const dateToCompare = this.$moment(fin)
      return dateToCompare.isAfter(currentDate)
    },
    selectedSite(site) {
      site.type = "site"
      const LibSite = site
      this.selectedCurrentSite(LibSite)
    },
    async editDefaultStore( statusDefault) {
      //if (!store?.isDefault) {
        const body = {
          defaultIDs: this.selectedStores , //  store?._id,
         // disabledIds: this.User?.restaurant ?? [],
          statusDefault: statusDefault
        }
        this.$swal({
          title: statusDefault == true ? "Voulez-vous définir ces restaurants comme sélection par défaut ?" : "Voulez-vous supprimer la sélection par défaut de ces restaurants ?",
          showDenyButton: false,
          showCancelButton: true,
          confirmButtonText: "Oui, Enregistrer",
          cancelButtonText: "Non, Annuler",
        }).then(async (result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            await this.defaultStore(body)
            window.location.reload()
          }
        });
      // } else {
      //   this.$swal({
      //     title: "Ce restaurant est déjà sélectionné par défaut",
      //     showDenyButton: false,
      //     showCancelButton: true,
      //     showConfirmButton: false,
      //     cancelButtonText: "Ok",
      //   })
      // }



    }
  },
};
</script>
<!-- eslint-disable -->
<style>
.selected-default-store {
  background-color: #a3eaa3;
}


.btn-default-store {
  background-color: orange !important;
}
</style>