<!-- eslint-disable -->
<template>
  <div class="row">

    <div class="col-md-12 grid-margin stretch-card">
      <div class="card">
        <div class="card-header text-center">
          <span>
           <b>Heure de début : </b>{{ formaTime(TimeDebutCalclule?.TimeDebut) }}
            <b>Heure de fin : </b> {{ formaTime(TimeDebutCalclule?.TimeFin) }}
          </span>
        </div>
      </div>
    </div>

    <div class="col-md-6 grid-margin stretch-card">
      <div class="card">
        <div class="card-header">
          <h3 class="card-title text-left">Règlements par mode de paiement</h3>

        </div>
        <div v-if="dashboard.list_reglements_groupby_famille &&
          dashboard.list_reglements_groupby_famille.length > 0
        " class="card-body">
          <div class="table-responsive  ">

            <DataTable showGridlines stripedRows :value="dashboard.list_reglements_groupby_famille">
              <Column field="LibFamille" header="Mode de paiement" sortable> </Column>
              <Column field="LibCaisse" header="Caisse" sortable> </Column>
              <Column field="TotalValeur" header="règlements (€)" sortable>
                <template #body="slotProps">
                  {{ slotProps?.data?.TotalValeur?.toFixed(2) }} €
                </template>
              </Column>
            </DataTable>
          </div>
        </div>
        <div v-else class="card-body d-flex align-items-center justify-content-center">
          <datanotfound :text="$t(`content.message.datanotfound`)" />
        </div>
      </div>
    </div>
    <div class="col-md-6 grid-margin stretch-card">
      <div class="card">
        <div class="card-header">
          <h3 class="card-title text-left">TOTAL Règlements par heure</h3>

        </div>
        <div v-if="ca_and_regelement_per_hour && ca_and_regelement_per_hour.length > 0" class="card-body">
          <div class="table-responsive tableClasss">

            <DataTable showGridlines stripedRows :value="ca_and_regelement_per_hour">
              <Column field="heur" header="Heure" sortable> </Column>
              <Column field="total" header="Total (€)" sortable>
                <template #body="slotProps">
                  {{ slotProps?.data?.total?.toFixed(2) }} €
                </template>
              </Column>
            </DataTable>
          </div>
        </div>
        <div v-else class="card-body d-flex align-items-center justify-content-center">
          <datanotfound :text="$t(`content.message.datanotfound`)" />
        </div>
      </div>
    </div>

  </div>
</template>
<!-- eslint-disable -->
<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import LineChart from "@/components/chartVue/ChartLine.vue";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import { formaDate, formaTime } from "@/Utils/globale.js";

export default {
  components: { LineChart, VueDatePicker },
  name: "mongoDATA",
  watch: {
    "datePicker.date.range"(newDate, oldDate) {
      if (newDate !== oldDate)
        this.callFunctions(
          this.datePicker.date.from,
          this.datePicker.date.to,
          this.datePicker.defaultDates.type
        );
    },
  },
  data() {
    return {
      dashboard: {

        ca_per_hour: [],
        payments_per_hour: [],
        list_reglements_groupby_famille: [],
      },


    };
  },
  mounted() {
    this.callFunctions(
      this.datePicker.date.from,
      this.datePicker.date.to,
      this.datePicker.defaultDates.type
    );

  },
  created() { },
  unmounted() {
  },
  methods: {
    formaDate, formaTime,
    ...mapMutations(["setTicketID"]),
    ...mapActions([
      "getReglements",
      "BoardPaymentsPerHour",

    ]),

    async getPaymentsPerHour(from, to) {
      let qte = "qte";
      let page = 1;
      let limit = 1; // select first element
      let self = this;
      this.dashboard.payments_per_hour = await this.BoardPaymentsPerHour({
        url: "board/payment-per-hour",
        page: page,
        limit: limit,
        range: self.datePicker.date.range,
        vendeur: "vendeur",
        site: self.currentSite?._id,
        qte: qte,
        WhereLibInArray: [
          { LibValeur: 'vendeur', LibKey: 'LibVendeur' },
          { LibValeur: 'caisse', LibKey: 'LibCaisse' },
          { LibValeur: ['VENTE', 'TRANSFERT', 'ANNULATION'], LibKey: 'Operation' },
        ]
      });
    },

    async getReglementsGroupByFamille(from, to) {
      let qte = "qte";
      let vendeur = "vendeur";
      let self = this;
      this.dashboard.list_reglements_groupby_famille = await this.getReglements({
        url: "entetes/reglements",
        range: self.datePicker.date.range,
        vendeur: vendeur,
        site: self.currentSite?._id,
        qte: qte,
      });
    },

    callFunctions(from, to, type) {
      if (this.currentSite?._id) {
        let range = from
        this.getReglementsGroupByFamille(range, to); // par mode de Piaements

        this.getPaymentsPerHour(range, to);

      }
    },




  },
  computed: {
    ...mapGetters({

      isAuthenticated: "isAuthenticated",
      isRole: "isRole",
      res: "StateRes",
      currentSite: "currentSite",
      listChiffreAffaires: "listChiffreAffaires",
      TimeDebutCalclule: "TimeDebutCalclule"
    }),
    ...mapState(["ticket_id", "datePicker"]),
    ca_and_regelement_per_hour() {
      // Create a map to store totals by heur
      let resultMap = new Map();

      // Function to update resultMap with totals from array
      const updateMap = (array) => {
        array.forEach(item => {
          if (resultMap.has(item.heur)) {
            resultMap.set(item.heur, resultMap.get(item.heur) + item.total);
          } else {
            resultMap.set(item.heur, item.total);
          }
        });
      };

      // Update resultMap with totals from both arrays 
      updateMap(this.dashboard.payments_per_hour);

      // Convert resultMap to array format
      return Array.from(resultMap, ([heur, total]) => ({ heur, total }));
    }

  },
};
</script>
<!-- eslint-disable -->

<style>
.selected-default-dates {
  background: #084593 !important;
  color: #fff !important;
}

.card-100 .card {
  width: 100%;
}

.card .card-title {
  margin-bottom: 0px !important;
}



.type_ca span.brut {
  border-left: 1px solid #d9d9d9;
  border-right: 0px solid #d9d9d9;
  border-radius: 2px 0 0 2px;
}

.type_ca span.net {
  border-left: 0px solid #d9d9d9;
  border-right: 1px solid #d9d9d9;
  border-radius: 0 2px 2px 0;
}

.btn-event-export {
  border: 1px solid #bdbdbd;
  padding: 5px;
  text-align: center;
  background: #fff;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}

.statistique-font-size {
  font-size: 20px;
}

.statistique {
  font-weight: 600;
  margin-top: 10px !important;
  display: block;
  margin: 0px !important;
}

.height-100 {
  height: 100%;
}

.geolocation.latlong {
  font-size: 15px;
}

.font-size-20 {
  font-size: 14px;
}

.card-body {
  padding: 0.8rem 0.8rem !important;
}


.percentage svg {
  margin-right: 4px;
}

.percentage .red {
  color: red !important;
}

.percentage .green,
.percentage {
  color: #14e114;
}

.percentage {
  width: 90px;
  display: inline-block;
}
</style>
