<!-- eslint-disable -->
<template>
  <!-- Modal -->
  <div class="modal fade" ref="myModal" id="modalAdd" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-labelledby="modalAddLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <div class="noble-ui-logo d-block mb-2"> Créer un<span>&nbsp; Codecsi</span></div>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <form class="forms-sample" @submit.prevent="SubmitData">
          <div class="modal-body">
            <div class="row" v-if="show">
              <div class="col-md-12 ps-md-0">
                <div class="auth-form-wrapper px-4 py-2">
                  <div class="row">
                    <div class="col-md-12 mb-3">
                      <div class="">
                        <label for="libel" class="form-label">Nom</label>
                        <input type="text" class="form-control" id="libel" placeholder="libel" v-model="form.libel" />
                      </div>
                      <div class="errors-response" v-if="v$.form.libel.$error">
                        <p v-if="!v$.form.libel.required.$response">{{ $t('validation.required') }}
                        </p>
                        <p class="error" v-if="!v$.form.libel.minLength.$response">
                          {{ $t('validation.min.string', {
                            attribute: 'libel', min: v$.form.libel.minLength.$params.min
                          }) }}
                        </p>
                        <p class="error" v-if="!v$.form.libel.maxLength.$response">
                          {{ $t('validation.max.string', {
                            attribute: 'libel', max: v$.form.libel.maxLength.$params.max
                          }) }}
                        </p>
                      </div>
                    </div>
                    <div class="col-md-12 mb-3">
                      <div class="">
                        <label for="code" class="form-label">code</label>
                        <input type="text" class="form-control" id="code" placeholder="code" v-model="form.code" />
                      </div>
                      <div class="errors-response" v-if="v$.form.code.$error">
                        <p v-if="!v$.form.code.required.$response">{{ $t('validation.required') }}
                        </p>
                        <p class="error" v-if="!v$.form.code.minLength.$response">
                          {{ $t('validation.min.string', {
                            attribute: 'code', min: v$.form.code.minLength.$params.min
                          }) }}
                        </p>
                        <p class="error" v-if="!v$.form.code.maxLength.$response">
                          {{ $t('validation.max.string', {
                            attribute: 'code', max: v$.form.code.maxLength.$params.max
                          }) }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Annuler</button>
            <button type="submit" class="btn btn-primary"
              :data-bs-dismiss="!this.v$.$invalid ? 'modal' : ''">Enregistrer</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<!-- eslint-disable -->

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import useVuelidate from "@vuelidate/core";
import { required, minLength, maxLength, email, requiredIf, decimal, numeric, helpers, min } from "@vuelidate/validators";
const { withParams } = helpers;
import { alertSwal } from "@/Utils/globale.js";


export default {
  props: ['isModalOpen'],
  computed: {
    ...mapState({ status: "status" }),
    ...mapGetters({ isRole: "isRole", isAuthenticated: "isAuthenticated" })
  },
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    return {
      form: {
        libel: {
          required,
          minLength: minLength(1),
          maxLength: maxLength(40)
        },
        code: {
          required,
          minLength: minLength(1),
          maxLength: maxLength(40)
        }

      }
    };
  },
  data() {
    return {
      form: {
        libel: "",
        code: "",
        price: null,
        dateDebut: null,
        dateFin: null,
        nbMonths: 12,
        // usedDate: null,
        // storeID: null,
      },
      show: true,
    };
  },
  methods: {
    ...mapActions(["createCodecsi"]),
    emptyState() {
      this.form.libel = ""
      this.form.code = ""
      this.form.price = null
      this.form.dateDebut = null
      this.form.dateFin = null
      this.form.nbMonths = 12
      this.show = true
    },


    async SubmitData() {
      this.v$.$touch();
      if (
        !this.v$.$invalid
      ) {
        const codecsi = {
          libel: this.form.libel,
          code: this.form.code,
          price: this.form.price,
          dateDebut: this.form.dateDebut,
          dateFin: this.form.dateFin,
          nbMonths: this.form.nbMonths,
          isUsed: false,
          isEnabled: true
        };
        // pass the image also
        try {
          await this.createCodecsi(codecsi);
          alertSwal('success', this.$t('alertswal.added', { champ: 'Code CSI' }))
          this.emptyState()
          this.$emit('fetchCodecsisData');
        } catch (error) {
          console.log(error)
          if (error.response.status === 403) {
            alertSwal('error', this.$t('forbidden'))
            return;
          } else if (error.response.status === 400) {
            alertSwal('error', error.response?.data?.message)
            return;
          }
          alertSwal("error", "Il y a un problème, veuillez réessayer s'il vous plaît");
        }
      } else {
        console.log('Form has errors. Please fix them.');
        return;
      }
    },
  },
};
</script>
