<!-- eslint-disable -->
<template>
  <div class="d-flex align-items-center justify-content-center">
    <div class="row w-100 mx-0 auth-page">
      <div class="col-md-12 mb-4">
        <span class="type_ca float-start">
          <span>
            <router-link to="/managers">Revenir</router-link>
          </span>
        </span>
      </div>
      <div class="col-md-12 col-xl-12 mx-auto">
        <div class="card">
          <div class="row">
            <div class="col-md-12 ps-md-0">
              <div class="auth-form-wrapper px-4 py-5">
                <a href="#" class="noble-ui-logo d-block mb-2">Modifier<span>&nbsp; Admin</span></a>
                <h5 class="text-muted fw-normal mb-4">
                  éditer un compte admin.
                </h5>
                <form class="forms-sample" @submit.prevent="SubmitData">
                  <div class="row">

                    <div class="col-md-6 mb-3">
                      <div class="">
                        <label for="exampleInputUsername1" class="form-label">Nom d'utilisateur</label>
                        <input type="text" readonly class="form-control" id="exampleInputUsername1"
                          autocomplete="Username" placeholder="Nom d'utilisateur" v-model="form.username" />
                      </div>
                      <div class="errors-response" v-if="v$.form.username.$error">
                        <p v-if="!v$.form.username.required.$response">{{ $t('validation.required') }}
                        </p>
                        <p class="error" v-if="!v$.form.username.minLength.$response">
                          {{ $t('validation.min.string', {
                            attribute: "Nom d'utilisateur", min: v$.form.username.minLength.$params.min
                          }) }}
                        </p>
                        <p class="error" v-if="!v$.form.username.maxLength.$response">
                          {{ $t('validation.max.string', {
                            attribute: "Nom d'utilisateur", max: v$.form.username.maxLength.$params.max
                          }) }}
                        </p>
                      </div>
                    </div>
                    <div class="col-md-6 mb-3">
                      <div class="">
                        <label for="userEmail" class="form-label">Adresse e-mail</label>
                        <input type="email" readonly class="form-control" id="userEmail" placeholder="Adresse e-mail"
                          autocomplete="Email" v-model="form.email" />
                      </div>
                      <div class="errors-response" v-if="v$.form.email.$error">
                        <p v-if="!v$.form.email.required.$response">{{ $t('validation.required') }}
                        </p>
                        <p v-if="!v$.form.email.email.$response">{{ $t('validation.email') }}
                        </p>
                        <p class="error" v-if="!v$.form.email.minLength.$response">
                          {{ $t('validation.min.string', {
                            attribute: 'email', min: v$.form.email.minLength.$params.min
                          }) }}
                        </p>
                        <p class="error" v-if="!v$.form.email.maxLength.$response">
                          {{ $t('validation.max.string', {
                            attribute: 'email', max: v$.form.email.maxLength.$params.max
                          }) }}
                        </p>
                      </div>
                    </div>
                    <div class="col-md-6 mb-3">
                      <div class="">
                        <label for="exampleInputFirstname1" class="form-label">Prénom</label>
                        <input type="text" class="form-control" id="exampleInputFirstname1" placeholder="Prénom"
                          autocomplete="first-name" v-model="form.firstName" />
                      </div>
                      <div class="errors-response" v-if="v$.form.firstName.$error">
                        <p v-if="!v$.form.firstName.required.$response">{{ $t('validation.required') }}
                        </p>
                        <p class="error" v-if="!v$.form.firstName.minLength.$response">
                          {{ $t('validation.min.string', {
                            attribute: 'Prénom', min: v$.form.firstName.minLength.$params.min
                          }) }}
                        </p>
                        <p class="error" v-if="!v$.form.firstName.maxLength.$response">
                          {{ $t('validation.max.string', {
                            attribute: 'Prénom', max: v$.form.firstName.maxLength.$params.max
                          }) }}
                        </p>
                      </div>
                    </div>
                    <div class="col-md-6 mb-3">
                      <div class="">
                        <label for="exampleInputLastname1" class="form-label">Nom de famille</label>
                        <input type="text" class="form-control" id="exampleInputLastname1" placeholder="Nom de famille"
                          autocomplete="last-name" v-model="form.lastName" />
                      </div>
                      <div class="errors-response" v-if="v$.form.lastName.$error">
                        <p v-if="!v$.form.lastName.required.$response">{{ $t('validation.required') }}
                        </p>
                        <p class="error" v-if="!v$.form.lastName.minLength.$response">
                          {{ $t('validation.min.string', {
                            attribute: 'Nom', min: v$.form.lastName.minLength.$params.min
                          }) }}
                        </p>
                        <p class="error" v-if="!v$.form.lastName.maxLength.$response">
                          {{ $t('validation.max.string', {
                            attribute: 'Nom', max: v$.form.lastName.maxLength.$params.max
                          }) }}
                        </p>
                      </div>
                    </div>
                    <div class="col-md-6 mb-3">
                      <div class="">
                        <label for="userPassword" class="form-label">Mot de passe</label>
                        <input type="password" class="form-control" id="userPassword" autocomplete="current-password"
                          placeholder="Mot de passe" v-model="form.password" />
                      </div>
                      <div class="errors-response" v-if="v$.form.password.$error">
                        <!-- <p v-if="!v$.form.password.required.$response">{{ $t('validation.required') }}
                        </p> -->
                        <p class="error" v-if="!v$.form.password.minLength.$response">
                          {{ $t('validation.min.string', {
                            attribute: 'Mot de passe', min: v$.form.password.minLength.$params.min
                          }) }}
                        </p>
                        <p class="error" v-if="!v$.form.password.maxLength.$response">
                          {{ $t('validation.max.string', {
                            attribute: 'Mot de passe', max: v$.form.password.maxLength.$params.max
                          }) }}
                        </p>
                      </div>
                    </div>
                    <div class="col-md-6 mb-3">
                      <div class="">
                        <label for="ConfirmPassword" class="form-label">Confirmer le mot de passe</label>
                        <input type="password" class="form-control" id="ConfirmPassword" autocomplete="confirm-password"
                          placeholder="Confirmer le mot de passe" v-model="form.cnfpassword" />
                      </div>
                      <div class="errors-response" v-if="v$.form.cnfpassword.$error">
                        <p v-if="!v$.form.cnfpassword.required.$response">{{ $t('validation.required') }}
                        </p>
                        <p class="error" v-if="!v$.form.cnfpassword.sameAsPassword.$response">
                          {{ $t('validation.password') }}
                        </p>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="row">
                        <div class="col-md-8">
                          <div class="mb-3">
                            <label class="form-label">Télécharger Avatar:</label>
                            <div class="row">
                              <div class="col-md-12">
                                <FileUpload mode="basic" accept="image/*" :maxFileSize="1000000" :customUpload="true"
                                  @select="uploadImage" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <!--  img avatar -->
                        <div class="col-md-4">
                          <div class="mb-3">
                            <div class="d-flex align-items-center">
                              <img class="img-lg rounded-circle"
                                :src="!avatar ? user.avatar && BaseUrl + user.avatar : avatar" alt="avatar" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12 mt-5">
                      <button type="submit" class="btn btn-primary text-white w-100">
                        Modifier</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<!-- eslint-disable -->
<script>
import { mapGetters, mapActions } from "vuex";

import useVuelidate from "@vuelidate/core";
import { required, minLength, maxLength, email, requiredIf, decimal, numeric, helpers, sameAs } from "@vuelidate/validators";
const { withParams } = helpers;
import { alertSwal } from "@/Utils/globale.js";

export default {
  name: "EditmanagerView",
  computed: {
    ...mapGetters({ isRole: "isRole", isAuthenticated: "isAuthenticated", Username: "StateUsername", BaseUrl: "StateBase" }),
  },
  mounted() {
    const get = async () => {
      this.user = await this.getManagerByName(this.Username);
      this.form.id = this.user._id;
      this.form.username = this.user.username;
      this.form.email = this.user.email;
      this.form.firstName = this.user.firstName;
      this.form.lastName = this.user.lastName;
    };
    if (this.isAuthenticated && this.$store.state.roles.includes(this.isRole))
      get();
  },
  data() {
    return {
      avatar: "",
      user: {
        _id: "",
        username: "",
        email: "",
        firstName: "",
        lastName: "",
      },
      image_file: null,
      form: {
        id: "",
        username: "",
        email: "",
        firstName: "",
        lastName: "",
        password: "",
        cnfpassword: "",
      }
    };
  },
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    return {
      form: {
        username: {
          required,
          minLength: minLength(1),
          maxLength: maxLength(20)
        },
        email: {
          email,
          required,
          minLength: minLength(1),
          maxLength: maxLength(350)
        },
        firstName: {
          required,
          minLength: minLength(1),
          maxLength: maxLength(20)
        },
        lastName: {
          required,
          minLength: minLength(1),
          maxLength: maxLength(20)
        },
        password: {
          minLength: minLength(6),
          maxLength: maxLength(20)
        },
        cnfpassword: {
          required: requiredIf(function () {
            return (this.form.password != '')
          }),
          sameAsPassword: sameAs(this.form.password)
        },

      }
    };
  },
  methods: {

    ...mapActions([
      "getManagerByName",
      "updateManager",
    ]),
    uploadImage(event) {
      this.image_file = event.files[0];
      if (event.files[0].objectURL) {
        this.avatar = event.files[0].objectURL;
      }
    },
    async SubmitData() {
      this.v$.$touch();
      if (
        !this.v$.$invalid
      ) {
        const user = {
          id: this.user._id,
          username: this.user.username,
          email: this.user.email,
          firstName: this.form.firstName,
          lastName: this.form.lastName,
          password: this.form.password,
          role: "admin",
          image: this.image_file,
          restaurant: []
        };
        try {
          if (!user.password) {
            delete user.password;
          }
          await this.updateManager(user);
          this.$router.push({ name: "admins" });
          alertSwal("success", "Admin modifiée avec success");

        } catch (error) {
          console.log(error);
          if (error.response.status === 403) {
            alertSwal("error", "Forbidden");
            return;
          }
          alertSwal("error", "Il y a un problème, veuillez réessayer s'il vous plaît");
        }
      } else {
        console.log('Form has errors. Please fix them.');
        return;
      }
    },
  },
};
</script>
