<!-- eslint-disable -->
<template>
  <div class="row">
    <div class="col-md-12 grid-margin stretch-card">
      <div class="card">
        <div class="card-header d-flex align-items-center justify-content-between">
          <h3 class="card-title float-start">CA</h3>
        </div>
        <div class="card-body position-relative min-h-100">
          <SectionLoaderView v-if="isRequestChiffreAffairesChartJs" />
          <div v-if="chartjs.chartData" class="mb-2 text-center">
            <span>
              Date du
              <b>
                {{ datePicker.date.from ? $moment(datePicker.date.from).format("DD/MM/YYYY") : "DD/MM/YYYY" }}
              </b>
              au
              <b>
                {{ datePicker.date.to ? $moment(datePicker.date.to).format("DD/MM/YYYY") : "DD/MM/YYYY" }}
              </b>


            </span>
          </div>
          <div v-if="chartjs.chartData" class="row mb-2">
            <div class="col-md-6">
              <span class="statistique statistique-font-size font-weight-bold mb-0">
                {{ chiffre_affaire_total.toFixed(2) }} €
              </span>
            </div>
            <div class="col-md-12">
              <div class="type_ca float-end">
                <span class="brut" :class="{ active: type_ca == 'MontantHT' }" @click="ChangeCAType('brut')">
                  Montant HT
                </span>
                <span class="net" :class="{ active: type_ca == 'MontantTTC' }" @click="ChangeCAType('net')">Montant
                  TTC</span>
              </div>
            </div>
          </div>
          <LineChart v-if="chartjs.chartData" :chartData="chartjs.chartData" />
        </div>
      </div>
    </div>
    <div class="col-md-12 grid-margin stretch-card">
      <div class="card">
        <div class="card-header text-center">
          <span>
            <b>Heure de début : </b>{{ formaTime(TimeDebutCalclule?.TimeDebut) }}
            <b>Heure de fin : </b> {{ formaTime(TimeDebutCalclule?.TimeFin) }}
          </span>
        </div>
      </div>
    </div>

    <div class="col-md-6 grid-margin stretch-card">
      <div class="card">
        <div class="card-header">
          <h3 class="card-title text-left">CA par caisse</h3>

        </div>
        <div v-if="dashboard.chiffre_affaires_par_caisse.length > 0" class="card-body">
          <div class="table-responsive tableClasss">
            <DataTable showGridlines stripedRows :value="dashboard.chiffre_affaires_par_caisse">
              <Column field="LibCaisse" header="Caisse" sortable></Column>
              <Column field="ca" header="Total" sortable>
                <template #body="slotProps">
                  {{ slotProps?.data?.ca?.toFixed(2) }} €
                </template>
              </Column>
            </DataTable>
          </div>
        </div>
        <div v-else class="card-body d-flex align-items-center justify-content-center">
          <datanotfound :text="$t(`content.message.datanotfound`)" />
        </div>
      </div>
    </div>
    <div class="col-md-6 grid-margin stretch-card">
      <div class="card">
        <div class="card-header">
          <h3 class="card-title text-left">TOTAL CA par heure</h3>

        </div>
        <div v-if="ca_and_regelement_per_hour && ca_and_regelement_per_hour.length > 0" class="card-body">
          <div class="table-responsive tableClasss">

            <DataTable showGridlines stripedRows :value="ca_and_regelement_per_hour">
              <Column field="heur" header="Heure" sortable> </Column>
              <Column field="total" header="Total (€)" sortable>
                <template #body="slotProps">
                  {{ slotProps?.data?.total?.toFixed(2) }} €
                </template>
              </Column>
            </DataTable>
          </div>
        </div>
        <div v-else class="card-body d-flex align-items-center justify-content-center">
          <datanotfound :text="$t(`content.message.datanotfound`)" />
        </div>
      </div>
    </div>

  </div>
</template>
<!-- eslint-disable -->
<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import LineChart from "@/components/chartVue/ChartLine.vue";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import { formaDate, formaTime } from "@/Utils/globale.js";


export default {
  components: { LineChart, VueDatePicker },
  name: "mongoDATA",
  watch: {
    "datePicker.date.range"(newDate, oldDate) {
      if (newDate !== oldDate)
        this.callFunctions(
          this.datePicker.date.from,
          this.datePicker.date.to,
          this.datePicker.defaultDates.type
        );
    },
  },
  data() {
    return {
      apiKey: "d5d29bfed8ee0aa4b167f3bbd42204ce",
      dashboard: {

        ca_per_hour: [],
        payments_per_hour: [],
        revenu_statistques: [],

        chiffre_affaires_par_caisse: [],

      },
      chartjs: {
        ca_chartjs: {},
        couverts_chartjs: {},
        chartData: [],
        FilterBy: "hour",
      },
      chiffre_affaire_total: 0,
      type_ca: "MontantTTC",

    };
  },
  mounted() {
    this.callFunctions(
      this.datePicker.date.from,
      this.datePicker.date.to,
      this.datePicker.defaultDates.type
    );

  },
  created() { },
  unmounted() {
  },
  methods: {
    formaDate, formaTime ,
    ...mapMutations(["setTicketID"]),
    ...mapActions([

      "BoardChiffreAffaires",
      "BoardCaPerHour",
      "BoardPaymentsPerHour",
      "BoardChiffreAffairesChartJs"
    ]),


    callFunctions(from, to, type) {
      if (this.currentSite?._id) {
        let range = from


        this.getChiffreAffairesParCaisse(range, to);

        this.getCaPerHour(range, to);
        //this.getPaymentsPerHour(range, to);

        this.getChiffreAffairesChartJs(range, to, this.chartjs.FilterBy);
      }
    },

    /** DASHBOARD    */


    async getChiffreAffairesParCaisse(from, to) {
      let qte = "qte";
      let page = 1;
      let limit = 0;
      let groupbycaisse = "oui";
      let self = this;
      this.dashboard.chiffre_affaires_par_caisse = await this.BoardChiffreAffaires({
        url: "board/chiffre-affaires",
        page: page,
        limit: limit,
        range: self.datePicker.date.range,

        vendeur: "vendeur",
        site: self.currentSite?._id,
        qte: qte,
        groupbycaisse: groupbycaisse,
        WhereLibInArray: [
          { LibValeur: 'vendeur', LibKey: 'LibVendeur' },
          { LibValeur: 'caisse', LibKey: 'LibCaisse' },
          { LibValeur: ['VENTE', 'TRANSFERT', 'ANNULATION'], LibKey: 'Operation' },
        ]
      });
    },



    async getCaPerHour(from, to) {
      let qte = "qte";
      let page = 1;
      let limit = 1; // select first element
      let self = this;
      this.dashboard.ca_per_hour = await this.BoardCaPerHour({
        url: "board/ca-per-hour",
        page: page,
        limit: limit,
        range: self.datePicker.date.range,

        vendeur: "vendeur",
        site: self.currentSite?._id,
        qte: qte,
        WhereLibInArray: [
          { LibValeur: 'vendeur', LibKey: 'LibVendeur' },
          { LibValeur: 'caisse', LibKey: 'LibCaisse' },
          { LibValeur: ['VENTE', 'TRANSFERT', 'ANNULATION'], LibKey: 'Operation' },
        ]
      });
    },
    // async getPaymentsPerHour(from, to) {
    //   let qte = "qte";
    //   let page = 1;
    //   let limit = 1; // select first element
    //   let self = this;
    //   this.dashboard.payments_per_hour = await this.BoardPaymentsPerHour({
    //     url: "board/payment-per-hour",
    //     page: page,
    //     limit: limit,
    //     range: self.datePicker.date.range,
    //     vendeur: "vendeur",
    //     site: self.currentSite?._id,
    //     qte: qte,
    //     WhereLibInArray: [
    //       { LibValeur: 'vendeur', LibKey: 'LibVendeur' },
    //       { LibValeur: 'caisse', LibKey: 'LibCaisse' },
    //       { LibValeur: ['VENTE', 'TRANSFERT', 'ANNULATION'], LibKey: 'Operation' },
    //     ]
    //   });
    // },


    async getChiffreAffairesChartJs(from, to, groupby_date) {
      let groupby_dates = ''
      if (this.datePicker.defaultDates.type == 'today') {
        groupby_dates = 'hour'
      } else if (this.datePicker.defaultDates.type == 'yesterday') {
        groupby_dates = 'hour'
      } else if (this.datePicker.defaultDates.type == 'week') {
        groupby_dates = 'day'
      } else if (this.datePicker.defaultDates.type == 'month') {
        groupby_dates = 'day'
      } else if (this.datePicker.defaultDates.type == 'year') {
        groupby_dates = 'month'
      }
      let qte = "qte";
      let page = 1;
      let limit = 0; // select first element
      let self = this;
      this.chartjs.ca_chartjs = await this.BoardChiffreAffairesChartJs({
        url: "board/ca-chartjs",
        page: page,
        limit: limit,
        range: self.datePicker.date.range,

        vendeur: "vendeur",
        site: self.currentSite?._id,
        qte: qte,
        type_ca: self.type_ca,
        groupby_date: groupby_dates,
        WhereLibInArray: [
          { LibValeur: 'vendeur', LibKey: 'LibVendeur' },
          { LibValeur: 'caisse', LibKey: 'LibCaisse' },
          { LibValeur: ['VENTE', 'TRANSFERT', 'ANNULATION'], LibKey: 'Operation' },
        ]
      });
      let labels = [];
      let datasets = [];
      let datasetsCouverts = [];

      self.chiffre_affaire_total = 0;
      self.nb_couverts_total = 0;
      const dayNames = [
        "Dimanche",
        "Lundi",
        "Mardi",
        "Mercredi",
        "Jeudi",
        "Vendredi",
        "Samedi",
      ];
      //return dayNames[dayOfWeek];
      await this.chartjs.ca_chartjs.map((el) => {
        if (groupby_date == "day") {
          labels.push(dayNames[el.name_month - 1]);
        } else {
          labels.push(el.labels);
        }

        datasets.push(el?.chiffre_affaire?.toFixed(2));
        self.chiffre_affaire_total += el.chiffre_affaire;

      });
      this.chartjs.chartData = {};
      let ca_label = this.type_ca == "MontantHT" ? "CA HT" : "CA TTC";
      this.chartjs.chartData = {
        labels: labels,
        datasets: [
          {
            label: ca_label,
            borderColor: "yellow",
            data: datasets,
            fill: true,
            tension: 0.4,
            backgroundColor: '#abd8f5',
          },
        ],
      };

    },
    ChangeCAType(type) {
      this.type_ca = type == "brut" ? "MontantHT" : "MontantTTC";
      this.getChiffreAffairesChartJs(
        this.datePicker.date.from,
        this.datePicker.date.to,
        this.chartjs.FilterBy //  
      );
    },
    ChartJsfilterBy() {
      this.getChiffreAffairesChartJs(
        this.datePicker.date.from,
        this.datePicker.date.to,
        this.chartjs.FilterBy //   
      );
    },



  },
  computed: {
    ...mapGetters({
      isRequestChiffreAffairesChartJs: "isRequestChiffreAffairesChartJs",

      isAuthenticated: "isAuthenticated",
      isRole: "isRole",
      res: "StateRes",
      currentSite: "currentSite",
      listChiffreAffaires: "listChiffreAffaires",
      TimeDebutCalclule: "TimeDebutCalclule"
    }),
    ...mapState(["ticket_id", "datePicker"]),
    ca_and_regelement_per_hour() {
      // Create a map to store totals by heur
      let resultMap = new Map();

      // Function to update resultMap with totals from array
      const updateMap = (array) => {
        array.forEach(item => {
          if (resultMap.has(item.heur)) {
            resultMap.set(item.heur, resultMap.get(item.heur) + item.total);
          } else {
            resultMap.set(item.heur, item.total);
          }
        });
      };

      // Update resultMap with totals from both arrays
      updateMap(this.dashboard.ca_per_hour);
      //   updateMap(this.dashboard.payments_per_hour);

      // Convert resultMap to array format
      return Array.from(resultMap, ([heur, total]) => ({ heur, total }));
    }

  },
};
</script>
<!-- eslint-disable -->

<style>
.selected-default-dates {
  background: #084593 !important;
  color: #fff !important;
}

.card-100 .card {
  width: 100%;
}

.card .card-title {
  margin-bottom: 0px !important;
}



.type_ca span.brut {
  border-left: 1px solid #d9d9d9;
  border-right: 0px solid #d9d9d9;
  border-radius: 2px 0 0 2px;
}

.type_ca span.net {
  border-left: 0px solid #d9d9d9;
  border-right: 1px solid #d9d9d9;
  border-radius: 0 2px 2px 0;
}

.btn-event-export {
  border: 1px solid #bdbdbd;
  padding: 5px;
  text-align: center;
  background: #fff;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}

.statistique-font-size {
  font-size: 20px;
}

.statistique {
  font-weight: 600;
  margin-top: 10px !important;
  display: block;
  margin: 0px !important;
}

.height-100 {
  height: 100%;
}

.geolocation.latlong {
  font-size: 15px;
}

.font-size-20 {
  font-size: 14px;
}

.card-body {
  padding: 0.8rem 0.8rem !important;
}


.percentage svg {
  margin-right: 4px;
}

.percentage .red {
  color: red !important;
}

.percentage .green,
.percentage {
  color: #14e114;
}

.percentage {
  width: 90px;
  display: inline-block;
}
</style>
