<!-- eslint-disable -->
<template>
  <div class="modal fade" id="modalEdit" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-labelledby="modalEditLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <div class="noble-ui-logo d-block mb-2"> Modifier <span>&nbsp;un abonnement</span></div>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <form class="forms-sample" @submit.prevent="SubmitData">
          <div class="modal-body">
            <div class="row" v-if="show">
              <div class="col-md-12 ps-md-0">
                <div class="auth-form-wrapper px-4 py-2">
                  <div class="row">
                    <div class="col-md-12">
                      <label for="libel" class="form-label">Status</label>
                      <div class="d-flex justify-content align-items-end flex-wrap grid-margin">
                        <span class="w-50 type_ca float-end">
                          <span @click="UpdateStatusAbn(1)" type="button"
                            class="w-100 btn btn-info btn-icon-text me-3 mb-3 mb-md-0 "
                            :class="{ 'selected-default-dates': form.isEnabled }">Activer</span>
                        </span>
                        <span class="w-50 type_ca float-end">
                          <span @click="UpdateStatusAbn(0)" type="button"
                            class="w-100 btn btn-info btn-icon-text me-3 mb-3 mb-md-0 "
                            :class="{ 'selected-default-dates': !form.isEnabled }">Désactiver</span>
                        </span>
                      </div>
                    </div>
                    <div class="col-md-12 mb-3">
                      <div class=" ">
                        <label for="libel" class="form-label">Nom</label>
                        <input type="text" class="form-control" id="libel" placeholder="libel" v-model="form.libel" />
                      </div>
                      <div class="errors-response" v-if="v$.form.libel.$error">
                        <p v-if="!v$.form.libel.required.$response">{{ $t('validation.required') }}
                        </p>
                        <p class="error" v-if="!v$.form.libel.minLength.$response">
                          {{ $t('validation.min.string', {
                            attribute: 'libel', min: v$.form.libel.minLength.$params.min
                          }) }}
                        </p>
                        <p class="error" v-if="!v$.form.libel.maxLength.$response">
                          {{ $t('validation.max.string', {
                            attribute: 'libel', max: v$.form.libel.maxLength.$params.max
                          }) }}
                        </p>
                      </div>
                    </div>
                    <div class="col-md-12 mb-3">
                      <div class=" ">
                        <label for="description" class="form-label">Description</label>
                        <input type="text" class="form-control" id="description" placeholder="description"
                          v-model="form.description" />
                      </div>
                      <div class="errors-response" v-if="v$.form.description.$error">
                        <p v-if="!v$.form.description.required.$response">{{ $t('validation.required') }}</p>
                        <p class="error" v-if="!v$.form.description.maxLength.$response">
                          {{ $t('validation.max.string', {
                            attribute: 'description', max: v$.form.description.maxLength.$params.max
                          }) }}
                        </p>
                        <p class="error" v-if="!v$.form.description.minLength.$response">
                          {{ $t('validation.min.string', {
                            attribute: 'description', min: v$.form.description.minLength.$params.min
                          }) }}
                        </p>

                      </div>
                    </div>
                    <div class="col-md-12 mb-3">
                      <div class=" ">
                        <label for="price" class="form-label">Prix</label>
                        <input type="text" class="form-control" id="price" placeholder="price" v-model="form.price" />
                      </div>
                      <div class="errors-response" v-if="v$.form.price.$error">
                        <p v-if="!v$.form.price.required.$response">{{ $t('validation.required') }}</p>
                        <p v-if="!v$.form.price.decimal.$response">{{ $t('validation.numeric', { attribute: 'prix' }) }}
                        </p>
                        <span v-if="!v$.form.price.positive.$response">
                          {{ $t('validation.gt.numeric', { attribute: 'prix', value: 0 }) }}
                        </span>
                      </div>
                    </div>
                    <div class="col-md-12 mb-3">
                      <div class=" ">
                        <label for="nbMonths" class="form-label">Active</label>
                        <select v-model="form.nbMonths" class="form-control">
                          <option :value="1"> 1 mois</option>
                          <option :value="3"> 3 mois</option>
                          <option :value="6"> 6 Mois</option>
                          <option :value="9"> 9 Mois</option>
                          <option :value="12"> 1 Ans</option>
                        </select>
                      </div>
                      <div class="errors-response" v-if="v$.form.nbMonths.$error">
                        <p v-if="!v$.form.nbMonths.required.$response">{{ $t('validation.required') }}</p>
                        <p v-if="!v$.form.nbMonths.numeric.$response">{{ $t('validation.numeric', { attribute: 'mois' })
                        }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Annuler</button>
            <button type="submit" class="btn btn-primary"
              :data-bs-dismiss="!this.v$.$invalid ? 'modal' : ''">Enregistrer</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<!-- eslint-disable -->
<script>
import { mapGetters, mapActions } from "vuex";
import useVuelidate from "@vuelidate/core";
import { required, minLength, maxLength, email, requiredIf, decimal, numeric, helpers, min } from "@vuelidate/validators";
const { withParams } = helpers;
import { alertSwal } from "@/Utils/globale.js";

export default {
  props: ['abonnement'],
  computed: {
    ...mapGetters({ isRole: "isRole", isAuthenticated: "isAuthenticated", Username: "StateUsername", BaseUrl: "StateBase" }),
  },
  watch: {
    abonnement(newVal, oldVal) {
      this.fetchData()
    }
  },
  data() {
    return {
      form: {
        id: "",
        libel: "",
        description: "",
        price: null,
        nbMonths: null,
        isEnabled: false
      }
    };
  },
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    return {
      form: {
        libel: {
          required,
          minLength: minLength(1),
          maxLength: maxLength(20)
        },
        description: {
          required,
          minLength: minLength(1),
          maxLength: maxLength(100)
        },
        price: {
          required,
          decimal,
          positive: withParams({ type: 'positive' }, (value) => {
            return value > 0;
          }),
        },
        nbMonths: {
          required,
          numeric
        },

      }
    };
  },
  methods: {
    ...mapActions([
      "updateAbonnement"
    ]),
    UpdateStatusAbn(status) {
      this.form.isEnabled = status
    },
    fetchData() {
      this.form.id = this.abonnement?._id;
      this.form.libel = this.abonnement?.libel;
      this.form.description = this.abonnement?.description;
      this.form.price = this.abonnement?.price;
      this.form.nbMonths = this.abonnement?.nbMonths;
      this.form.isEnabled = this.abonnement?.isEnabled;
      this.show = true
    },
    emptyState() {
      this.form.id = ""
      this.form.libel = ""
      this.form.description = ""
      this.form.price = null
      this.form.nbMonths = null
      this.form.isEnabled = false
      this.show = true
    },
    async SubmitData() {

      this.v$.$touch();

      if (
        !this.v$.$invalid
      ) {
        const abonnement = {
          id: this.abonnement?._id,
          libel: this.form.libel,
          description: this.form.description,
          nbMonths: this.form.nbMonths,
          price: this.form.price,
          isEnabled: this.form.isEnabled,
        };
        try {
          await this.updateAbonnement(abonnement);
          this.emptyState()
          this.$emit('fetchAbonnementsData');
          alertSwal('success', this.$t('alertswal.updated', { champ: 'Type abonnement' }))
        } catch (error) {
          console.log(error)
          if (error.response.status === 403) {
            alertSwal("Forbidden");
            return;
          } else if (error.response.status === 400) {
            alertSwal('error', error.response?.data?.message)
            return;
          } else
            alertSwal("error", "Il y a un problème, veuillez réessayer s'il vous plaît");
        }
      } else {
        console.log('Form has errors. Please fix them.');
        return;
      }
    }
  },
};
</script>
