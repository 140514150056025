<!-- eslint-disable -->
<template>
  <div class="main-wrapper">
    <div class="page-wrapper full-page">
      <div class="page-content d-flex align-items-center justify-content-center">
        <div class="row w-100 mx-0 auth-page d-flex align-items-center justify-content-center">
          <div class="col-md-4 col-xl-6 mx-auto text-center">
            <img src="../../public/assets/img/logo_white.png" width="120" height="120" class="badge d-inline-block"
              alt="logo" />
            <h5 class="text-muteds fw-normal mb-4 mt-2">
              Bon retour ! Connectez-vous à votre compte.
            </h5>
          </div>
          <div class="col-md-8 col-xl-6 mx-auto">
            <div class="card">
              <div class="row">
                <div class="col-md-12 pl-5 pr-5">
                  <div v-if="!isResetPassword" class="auth-form-wrapper px-4 py-3">
                    <div class="noble-ui-logo text-center ">
                       Réinitialisation du <span>&nbsp;mot de passe  
                      </span>
                    </div>
                    <div class="text-center d-block mb-5">
                      <p>Veuillez saisir votre e-mail pour réinitialiser votre mot de passe </p>
                    </div>

                    <form class="forms-sample" @submit.prevent="submit">
                      <div class="row">
                        <div class="col-md-12 mb-3">
                          <div class="">
                            <label for="userEmail" class="form-label">Adresse e-mail / identifiant</label>
                            <input type="text" class="form-control" id="userEmail"
                              placeholder="Adresse e-mail / identifiant" name="email" v-model="form.email" />
                          </div>
                          <div class="errors-response" v-if="v$.form.email.$error">
                            <p v-if="!v$.form.email.required.$response">{{ $t('validation.required') }}
                            </p>
                            <!-- <p v-if="!v$.form.email.email.$response">{{ $t('validation.email') }}
                            </p> -->
                            <p class="error" v-if="!v$.form.email.minLength.$response">
                              {{ $t('validation.min.string', {
                                attribute: 'email', min: v$.form.email.minLength.$params.min
                              }) }}
                            </p>
                            <p class="error" v-if="!v$.form.email.maxLength.$response">
                              {{ $t('validation.max.string', {
                                attribute: 'email', max: v$.form.email.maxLength.$params.max
                              }) }}
                            </p>
                          </div>
                        </div> 
                        <div class="col-md-12">
                          <div class="mb-3 w-100">
                            <button type="submit" class="w-100 btn btn-primary me-2 mb-2 mb-md-0 text-white"> 
                              Réinitialiser</button>
                          </div>
                        </div>
                      </div>
                    </form>
                    <div class="col-md-12 mt-4 text-center">
                      <p> Déjà membre ?</p>
                      <router-link :to="{ name: 'login' }">
                        <span>Connectez-vous ici </span>
                      </router-link>
                    </div>
                  </div>
                  <div v-else class="auth-form-wrapper px-4 py-3">
                    <div class="noble-ui-logo text-center ">
                       Veuillez vérifier votre boîte mail <span>&nbsp;Un lien vous a été envoyé pour modifier votre mot de passe 
                      </span>
                    </div>
                     
                      <div class="col-md-12 mt-4 text-center">
                      
                      <router-link :to="{ name: 'login' }">
                        <span>Connectez-vous ici </span>
                      </router-link>
                    </div>
                     
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<!-- eslint-disable -->
<script>
import { mapActions, mapGetters , mapStates} from "vuex";

import useVuelidate from "@vuelidate/core";
import { required, minLength, maxLength, email, requiredIf, decimal, numeric, helpers, min } from "@vuelidate/validators";
const { withParams } = helpers;
import { alertSwal } from "@/Utils/globale.js";

export default {
  name: "PasswordForgotten",
  computed: {
    ...mapGetters({ isAuthenticated: "isAuthenticated" , isResetPassword: "isResetPassword"}),
    
  },
  data() {
    return {
      form: {
        email: "", 
      },
      showError: false,
    };
  },
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    return {
      form: {
        email: {
          email,
          required,
          minLength: minLength(1),
          maxLength: maxLength(350)
        } 

      }
    };
  },
  created() {
    if (this.isAuthenticated) {
      window.location.href = "/";
    }
    this.$store.state.isResetPassword = false
  },
  methods: {

    ...mapActions(["PasswordForgotten"]),
    async submit() {
      this.v$.$touch();
      if (
        !this.v$.$invalid
      ) {
        const User = {
          email: this.form.email 
        };
        try {
          await this.PasswordForgotten(User);
        } catch (error) {
          if (error.response.status === 403) {
            alertSwal("error", "Forbidden");
            return;
          } else
            alertSwal("error", "Il y a un problème, veuillez réessayer s'il vous plaît");
        }
        if (this.isAuthenticated) {
          window.location.href = "/";
        }
      } else {
        console.log('Form has errors. Please fix them.');
        return;
      }
    },
  },
};
</script>
<!-- eslint-disable -->
<style scoped > 
.main-wrapper {
   background-position: 50% !important;
   background-repeat: no-repeat !important;
   background-size: cover !important;
   background-image: url('../../public/assets/img/bg2.jpg');

 }

 .page-wrapper.full-page {
   background: rgba(0, 0, 0, 0.57);
   position: relative;
 }

 .text-muteds {
   color: #fff;
   font-size: 20px;
 }
</style>