<!-- eslint-disable -->
<template>
  <div class="w-100">
    <span type="button" class="w-100 btn btn-warning btn-icon-text  bg-red" @click="generatePdf"><i
        class="far fa-file-pdf"></i> Synthèse PDF</span>
  </div>
</template>
<!-- eslint-disable -->
<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import { Division } from "@/Utils/globale.js";
//import Vue3Html2pdf from "vue3-html2pdf";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
export default {
  props: ["dateFrom", "dateTo", "exportTo"],
  components: {
    //Vue3Html2pdf,
  },
  data() {
    return {
      clicked: false,
    };
  },
  methods: {
    async generatePdf() {
      var doc = new jsPDF({
        orientation: "p",
        unit: "mm",
        format: "a4",
        putOnlyUsedFonts: true,
        precision: 20,
      });

      let startY = 15;

      var pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();

      // Draw the line
      // startY += 2;
      doc.setDrawColor(0, 0, 0);
      doc.line(15, startY, pageWidth - 15, startY);
      doc.setDrawColor(0, 0, 0);
      doc.line(15, startY + 0.3, pageWidth - 15, startY + 0.3);

      let X_POS_CENTER = pageWidth / 2;
      startY += 6;
      doc.setFont("helvetica", "normal", "bold");
      doc.setFontSize(10);
      doc.text(this.exportTo.site.LibSite, X_POS_CENTER, startY, { align: "center" });
      if (this.exportTo?.site?.EmetteurSociete) {
        startY += 5;
        doc.setFont("helvetica", "normal", "normal");
        doc.setFontSize(8);
        doc.text(this.exportTo.site.EmetteurSociete, X_POS_CENTER, startY, {
          align: "center",
        });
      }

      if (this.exportTo?.site?.EmetteurAdresse) {
        startY += 4;
        doc.setFont("helvetica", "normal", "normal");
        doc.setFontSize(8);
        doc.text(this.exportTo.site.EmetteurAdresse, X_POS_CENTER, startY, {
          align: "center",
        });
      }

      if (this.exportTo?.site?.EmetteurCodePostal) {
        startY += 4;
        doc.setFont("helvetica", "normal", "normal");
        doc.setFontSize(8);
        doc.text(this.exportTo.site.EmetteurCodePostal.toString(), X_POS_CENTER, startY, {
          align: "center",
        });
      }

      if (this.exportTo?.site?.EmetteurVille) {
        startY += 4;
        doc.setFont("helvetica", "normal", "normal");
        doc.setFontSize(8);
        doc.text(this.exportTo.site.EmetteurVille.toString(), X_POS_CENTER, startY, {
          align: "center",
        });
      }

      if (this.exportTo?.site?.EmetteurPays) {
        startY += 4;
        doc.setFont("helvetica", "normal", "normal");
        doc.setFontSize(8);
        doc.text(this.exportTo.site.EmetteurPays.toString(), X_POS_CENTER, startY, {
          align: "center",
        });
      }

      if (this.exportTo?.site?.EmetteurTelephone) {
        startY += 4;
        doc.setFont("helvetica", "normal", "normal");
        doc.setFontSize(8);
        doc.text(this.exportTo.site.EmetteurTelephone.toString(), X_POS_CENTER, startY, {
          align: "center",
        });
      }

      if (this.exportTo?.site?.EmetteurCodeNAF) {
        startY += 4;
        doc.setFont("helvetica", "normal", "normal");
        doc.setFontSize(8);
        doc.text(
          this.exportTo.site.EmetteurCodeNAF.toString() +
          " " +
          this.exportTo.site.EmetteurNumTVA.toString(),
          X_POS_CENTER,
          startY,
          { align: "center" }
        );
      }

      if (this.exportTo?.site?.EmetteurSiret) {
        startY += 4;
        doc.setFont("helvetica", "normal", "normal");
        doc.setFontSize(8);
        doc.text(
          "SIRET " + this.exportTo.site.EmetteurSiret.toString(),
          X_POS_CENTER,
          startY,
          { align: "center" }
        );
      }

      startY += 8;

      doc.setFont("helvetica", "normal", "bold");
      doc.setFontSize(13);
      var text = "Synthèse Générale";
      doc.text(text, pageWidth / 2, startY, { align: "center" });

      // Draw the line
      startY += 2;
      doc.setDrawColor(0, 0, 0);
      doc.line(15, startY, pageWidth - 15, startY);
      doc.setDrawColor(0, 0, 0);
      doc.line(15, startY + 0.3, pageWidth - 15, startY + 0.3);

      // Draw line left
      var lineLength = startY;
      var startYline = 15;
      var startXline = startY;
      doc.setDrawColor(0, 0, 0);
      doc.line(15, startYline, 15, lineLength);
      doc.setDrawColor(0, 0, 0);
      doc.line(15 + 0.3, startYline, 15 + 0.3, lineLength);
      // end line left
      // Draw line right
      doc.setDrawColor(0, 0, 0);
      doc.line(pageWidth - 15, startYline, pageWidth - 15, lineLength);
      doc.setDrawColor(0, 0, 0);
      doc.line(pageWidth - 14.7, startYline, pageWidth - 14.7, lineLength);
      // end line right

      let startYY = startY + 24;
      doc.setFont("helvetica", "normal", "bold");
      doc.setFontSize(9);
      doc.text("DATE DE DEBUT", pageWidth - 80, startYY, "left");
      doc.setFont("helvetica", "normal", "normal");
      doc.setFontSize(9);
      doc.text(" : " + this.dateFrom, pageWidth - 15, startYY, "right");

      startYY += 6;
      doc.setFont("helvetica", "normal", "bold");
      doc.setFontSize(9);
      doc.text("DATE DE FIN", pageWidth - 80, startYY, "left");
      doc.setFont("helvetica", "normal", "normal");
      doc.setFontSize(9);
      doc.text(" : " + this.dateTo, pageWidth - 15, startYY, "right");


      startY += 24;
      doc.setFont("helvetica", "normal", "bold");
      doc.setFontSize(9);
      doc.text("SITE", 15, startY, "left");
      doc.setFont("helvetica", "normal", "normal");
      doc.setFontSize(9);
      doc.text(" : " + this.currentSite?.LibSite, 44, startY, "left");
      startY += 6;
      doc.setFont("helvetica", "normal", "bold");
      doc.setFontSize(9);
      doc.text("NOM SOCIÉTÉ", 15, startY, "left");
      doc.setFont("helvetica", "normal", "normal");
      doc.setFontSize(9);
      doc.text(" : " + this.currentSite?.EmetteurSociete, 44, startY, "left");
      startY += 6;
      doc.setFont("helvetica", "normal", "bold");
      doc.setFontSize(9);
      doc.text("NUMÉRO TVA", 15, startY, "left");
      doc.setFont("helvetica", "normal", "normal");
      doc.setFontSize(9);
      doc.text(
        " : Siret " + this.currentSite?.EmetteurSiret,
        44,
        startY,
        "left"
      );

      /** Familles */
      let TextY = startY + 15;
      let finalY = startY + 18;
      doc.setFont("helvetica", "normal", "bold");
      doc.setFontSize(11);
      doc.text("FAMILLES", 15, TextY, "left");
      var BodyResults = [];
      this.exportTo.famille.data[0].map((el) => {
        BodyResults.push([
          el._id,
          el.QuantityFamille.toString(),
          el.PrixTotalNetFamille.toFixed(2).toString() + " €",
        ]);
      });
      var FooterResults = [];
      FooterResults.push([
        "TOTAL ",
        this.exportTo.famille.data[1].TotalQuantityFamille.toString(),
        this.exportTo.famille.data[1].totalPrixTotalNetFamille.toFixed(2) + " €",
      ]);
      const columnsFamille = ["Nom", "Qte", "Montant"];
      autoTable(doc, {
        margin: { top: 30 },
        startY: finalY,
        pageBreak: "auto",
        theme: "striped",
        headStyles: {
          fillColor: [29, 30, 34],
          textColor: [255, 255, 255],
          cellPadding: 3,
        },
        footStyles: {
          fillColor: [102, 102, 102],
          textColor: [255, 255, 255],
          cellPadding: 2,
        },
        bodyStyles: { cellPadding: 2 },
        head: [columnsFamille],
        body: BodyResults,
        foot: FooterResults,
      });

      TextY = doc.autoTable.previous ? doc.autoTable.previous.finalY + 15 : TextY;
      finalY = doc.autoTable.previous ? doc.autoTable.previous.finalY + 18 : finalY;

      /** Reglements */
      doc.setFont("helvetica", "normal", "bold");
      doc.setFontSize(11);
      doc.text("REGLEMENTS", 15, TextY, "left");
      BodyResults = [];
      var QteReglements = 0;
      var totalReglements = 0;
       

      // Loop through the array and display count & Libelle

    

      // this.exportTo.reglements.data[0].forEach(item => {
      //   const key = Object.keys(item)[0]; // Get the dynamic key (TOTAL, CARTES, etc.)
     
        
      //   BodyResults.push([
      //     item[key].LibFamille,
      //     item[key].count.toString(),
      //     item[key].TotalValeur.toFixed(2).toString() + " €",
      //   ]);
      //   QteReglements += item[key].count;
      //   totalReglements += item[key].TotalValeur;


      // });
 
      this.exportTo.reglements.data[0].map((el) => {
        BodyResults.push([
          el.Libelle,
          el.count.toString(),
          el.TotalValeur.toFixed(2).toString() + " €",
        ]);
        QteReglements += el.count;
        totalReglements += el.TotalValeur;
      });
      FooterResults = [];
      FooterResults.push([
        "TOTAL REGLEMENTS ",
        QteReglements,
        totalReglements.toFixed(2).toString() + " €",
      ]);
      const columnsReglement = this.exportTo.reglements.columns;
      autoTable(doc, {
        margin: { top: 30 },
        startY: finalY,
        pageBreak: "auto",
        theme: "striped",
        headStyles: {
          fillColor: [29, 30, 34],
          textColor: [255, 255, 255],
          cellPadding: 3,
        },
        footStyles: {
          fillColor: [102, 102, 102],
          textColor: [255, 255, 255],
          cellPadding: 2,
        },
        bodyStyles: { cellPadding: 2 },
        head: [columnsReglement],
        body: BodyResults,
        foot: FooterResults,
      });


      TextY = doc.autoTable.previous ? doc.autoTable.previous.finalY + 15 : TextY;
      finalY = doc.autoTable.previous ? doc.autoTable.previous.finalY + 18 : finalY;

      /** tva */
      doc.setFont("helvetica", "normal", "bold");
      doc.setFontSize(11);
      doc.text("TVA", 15, TextY, "left");
      BodyResults = [];
      var ValTVA = 0;
      var ValHT = 0;
      var ValTTC = 0;
      this.exportTo.tva.data.map((el) => {
        BodyResults.push([
          el._id.Libelle,
          el.TotalValeurTVA.toFixed(2).toString(),
          el.TotalValeurHT.toFixed(2).toString() + " €",
          el.TotalValeurTTC.toFixed(2).toString() + " €",
        ]);
        ValTVA += el.TotalValeurTVA;
        ValHT += el.TotalValeurHT;
        ValTTC += el.TotalValeurTTC;
      });
      FooterResults = [];
      FooterResults.push([
        "TOTAL TVA ",
        ValTVA.toFixed(2).toString(),
        ValHT.toFixed(2).toString() + " €",
        ValTTC.toFixed(2).toString() + " €"
      ]);
      autoTable(doc, {
        margin: { top: 30 },
        startY: finalY,
        pageBreak: "auto",
        theme: "striped",
        headStyles: {
          fillColor: [29, 30, 34],
          textColor: [255, 255, 255],
          cellPadding: 3,
        },
        footStyles: {
          fillColor: [102, 102, 102],
          textColor: [255, 255, 255],
          cellPadding: 2,
        },
        bodyStyles: { cellPadding: 2 },
        head: [this.exportTo.tva.columns],
        body: BodyResults,
        foot: FooterResults,
      });


      TextY = doc.autoTable.previous ? doc.autoTable.previous.finalY + 15 : TextY;
      finalY = doc.autoTable.previous ? doc.autoTable.previous.finalY + 18 : finalY;

      /** Statistiques */
      doc.setFont("helvetica", "normal", "bold");
      doc.setFontSize(11);
      doc.text("STATISTIQUES", 15, TextY, "left");
      BodyResults = [];
      var QteStatistiques = 0;
      var totalStatistiques = 0;
      var totalMoyenne = 0;
      var moyenne = 0;
      this.exportTo.statistiques.data.map((el) => {
        moyenne =
          el.TotalValeur > 0 ? parseFloat(Division(el.TotalValeur, el.QteTotal)) : 0;
        BodyResults.push([
          el.Family,
          el.QteTotal.toString(),
          el.TotalValeur.toFixed(2).toString() + " €",
          moyenne.toFixed(2).toString() + " €"
        ]);
        QteStatistiques += el.QteTotal;
        totalStatistiques += el.TotalValeur;
        totalMoyenne += parseFloat(moyenne);
      });
      FooterResults = [];
      FooterResults.push([
        "TOTAL STATISTIQUES ",
        QteStatistiques,
        totalStatistiques.toFixed(2).toString() + " €",
        totalMoyenne.toFixed(2).toString() + " €"
      ]);
      autoTable(doc, {
        margin: { top: 30 },
        startY: finalY,
        pageBreak: "auto",
        theme: "striped",
        headStyles: {
          fillColor: [29, 30, 34],
          textColor: [255, 255, 255],
          cellPadding: 3,
        },
        footStyles: {
          fillColor: [102, 102, 102],
          textColor: [255, 255, 255],
          cellPadding: 2,
        },
        bodyStyles: { cellPadding: 2 },
        head: [this.exportTo.statistiques.columns],
        body: BodyResults,
        foot: FooterResults,
      });


      TextY = doc.autoTable.previous ? doc.autoTable.previous.finalY + 15 : TextY;
      finalY = doc.autoTable.previous ? doc.autoTable.previous.finalY + 18 : finalY;

      /*startY = finalY;
      doc.setFont("helvetica", "normal", "bold");
      doc.setFontSize(9);
      text = "Nouveau soldes des comptes";
      doc.text(text, 15, startY, "left");

      startY += 6;
      doc.setFont("helvetica", "normal", "bold");
      doc.setFontSize(9);
      text = "Nouveau solde des débiteurs";
      doc.text(text, 15, startY, "left");

      startY += 6;
      doc.setFont("helvetica", "normal", "bold");
      doc.setFontSize(9);
      text = "Date : " + this.dateFrom;
      doc.text(text, 15, startY, "left");

      doc.setFont("helvetica", "normal", "bold");
      doc.setFontSize(9);
      text =
        "Date d'édition : " +
        this.$moment(String(new Date())).format("MM-DD-YYYY HH:mm:ss");
      doc.text(text, pageWidth - 15, startY, "right"); */

      doc.save(this.exportTo.famille.fileName);
    },
  },
  mounted() {
  },
  computed: {
    ...mapGetters({
      isAuthenticated: "isAuthenticated",
      res: "StateRes",
      currentSite: "currentSite",
    }),
  },
};
</script>
<!-- eslint-disable -->
<style scoped>
.bg-red {
  background-color: red !important;
  font-size: 14px;
  color: #fff;
  border: none;
}
</style>