<!-- eslint-disable -->
<template>
  <div class="d-flex align-items-center justify-content-center" v-if="show">
    <div class="row w-100 mx-0 auth-page">
      <div class="col-md-12 col-xl-12 mx-auto">
        <div class="card">
          <div class="row">
            <div class="col-md-12 ps-md-0">
              <div class="auth-form-wrapper px-4 py-5">
                <div class="noble-ui-logo d-block mb-2">Inscrire<span>&nbsp;un
                    serveur</span></div>
                <h5 class="text-muted fw-normal mb-4">Créer un compte serveur.</h5>
                <form class="forms-sample" @submit.prevent="SubmitData">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="mb-3">
                        <label for="exampleInputUsername1" class="form-label">Nom d'utilisateur</label>
                        <input type="text" class="form-control" id="exampleInputUsername1" placeholder="Nom d'utilisateur"
                          v-model="username">
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="mb-3">
                        <label class="form-label">Adresse e-mail</label>
                        <input type="email" class="form-control" placeholder="Adresse e-mail" v-model="email">
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="mb-3">
                        <label for="exampleInputFirstname1" class="form-label">Prénom</label>
                        <input type="text" class="form-control" id="exampleInputFirstname1" placeholder="Prénom"
                          autocomplete="first-name" v-model="firstName" />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="mb-3">
                        <label for="exampleInputLastname1" class="form-label">Nom de famille</label>
                        <input type="text" class="form-control" id="exampleInputLastname1" placeholder="Nom de famille"
                          autocomplete="last-name" v-model="lastName" />
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="mb-3">
                        <label class="form-label">Mot de passe</label>
                        <input type="password" class="form-control" placeholder="Mot de passe" v-model="password">
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="mb-3">
                        <label for="ConfirmPassword" class="form-label">Confirmer le mot de
                          passe</label>
                        <input type="password" class="form-control" id="ConfirmPassword"
                          placeholder="Confirmer le mot de passe" v-model="cnfpassword">
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="mb-3">
                        <label class="form-label">Télécharger Avatar:</label>
                        <div class="row">
                          <div class="col-md-12">
                            <FileUpload mode="basic" accept="image/*" :maxFileSize="1000000" :customUpload="true"
                              @select="uploadImage" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="mb-3">
                        <label class="form-label">Choisir un
                          Restaurant</label>
                        <div class="row">
                          <div class="col-md-12">
                            <div class=" ">
                              <select class="form-select btn-outline-primary dropdown-toggle"
                                @change="onChangeSelect($event)">
                                <option selected disabled>
                                  Sélectionnez Restaurants.
                                </option>
                                <option v-for="(restaurant, index) in restaurants" v-bind:key="index"
                                  :value="restaurant._id">
                                  {{ index + 1 }}&nbsp;&nbsp;-&nbsp;&nbsp;{{ restaurant.LibSite.toUpperCase() }}</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <button type="submit" class="btn btn-primary text-white me-2 mb-2 mb-md-0">
                        Créer</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<!-- eslint-disable -->
<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "AddwaiterView",
  computed: {
    ...mapGetters({ isRole: "isRole", isAuthenticated: "isAuthenticated" }),
  },
  mounted() {
    const get = async () => {
      try {
        await this.getAllWaiter();
        this.restaurants = await this.getAllRestaurant();
        this.show = true;
      } catch (error) {
        if (error) {
          this.show = false;
          this.showAlert("Forbidden resource");
        }
      }
    };
    if (this.isAuthenticated && this.$store.state.roles.includes(this.isRole))
      get();
  },
  data() {
    return {
      image_file: null,
      username: "",
      email: "",
      firstName: "",
      lastName: "",
      password: "",
      cnfpassword: "",
      restaurant: "",
      show: true,
      restaurants: [],
    };
  },
  methods: {
    onChangeSelect(event) {
      this.restaurant = event.target.value;
    },
    showSuccess() {
      this.$swal({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        icon: "success",
        title: "edit is successfully done",
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
    },
    showAlert(error) {
      this.$swal({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        icon: "error",
        title: error,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
    },
    ...mapActions(["getAllWaiter", "getAllRestaurant", "createWaiter"]),
    uploadImage(event) {
      this.image_file = event.files[0];
    },
    async SubmitData() {
      // add more logic here
      const user = {
        username: this.username,
        email: this.email,
        firstName: this.firstName,
        lastName: this.lastName,
        password: this.password,
        role: "waiter",
        image: this.image_file,
        restaurant: this.restaurant,
      };
      try {
        if (
          this.username === "" ||
          this.email === "" ||
          this.firstName === "" ||
          this.lastName === "" ||
          this.password === "" ||
          this.cnfpassword === "" ||
          this.restaurant === ""
        ) {
          this.showAlert("Veuillez remplir tous les champs");
        } else if (this.password !== this.cnfpassword) {
          this.showAlert("Please confirm your password");
        } else {
          await this.createWaiter(user);
          this.$router.push({ name: "waiters" });
          this.showSuccess();
        }
      } catch (error) {
        console.log(error);
        if (error.response.status === 403) {
          this.showAlert("Forbidden");
          return;
        }
        this.showAlert("Il y a un problème, veuillez réessayer s'il vous plaît");
      }
    },
  },
};
</script>
